import UserFile, { type TUserFiles } from "@domains/File";

export default class FilesService {
    public static findFileIdByFileName(
        files: TUserFiles,
        fileName: string
    ): string | null {
        const targetFile: UserFile | undefined = files.find(
            (file: UserFile) => file.getFullName() === fileName
        );

        return !targetFile ? null : targetFile.getId();
    }

    public static getFilesFullNamesList(files: TUserFiles): string[] {
        return files.map((file: UserFile) => file.getFullName());
    }

    public static createFilesCopy(files: TUserFiles): TUserFiles {
        return new Array(...files);
    }

    public static excludeFileFromFilesList(
        file: UserFile,
        files: TUserFiles
    ): TUserFiles {
        const filesCopy: TUserFiles = this.createFilesCopy(files);

        return filesCopy.filter(
            (currentFile: UserFile) => currentFile.getId() !== file.getId()
        );
    }

    public static excludeFileIdFromIdsList(
        id: string,
        ids: string[]
    ): string[] {
        return ids.filter((currentKey: string) => currentKey !== id);
    }

    public static processFileNameDuplicates(
        file: UserFile,
        files: TUserFiles
    ): UserFile {
        let duplicatesIndex: number = 0;
        const initialFileName: string = file.getName();
        const filesNames: string[] = this.getFilesFullNamesList(files);

        while (filesNames.includes(file.getFullName())) {
            duplicatesIndex++;
            file.setName(`${initialFileName}(${duplicatesIndex})`);
        }

        return file;
    }

    private static readFileAsTextFromUserSystem(file: File): Promise<UserFile> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (event: ProgressEvent<FileReader>): void => {
                const uploadedFile = new UserFile();
                const result = event.target?.result;

                if (!result) {
                    reject(null);
                }

                uploadedFile.setFullName(file.name);
                uploadedFile.setContent(result!.toString());
                resolve(uploadedFile)
            };

            fileReader.onerror = (error) => {
                console.error(error);
                reject(error);
            };

            fileReader.readAsText(file); 
        });
    }

    public static async loadFilesFromUserSystem(): Promise<TUserFiles | null> {
        const fileInput: HTMLInputElement = document.createElement("input");

        fileInput.type = "file";
        fileInput.accept = ".js, .css";
        fileInput.multiple = true;

        return new Promise((resolve, reject) => {
            fileInput.onchange = async () => {
                const { files } = fileInput;

                if (!files) {
                    reject(null);
                }

                const outputFiles: Promise<TUserFiles> = Promise.all(
                    Array.from(files!).map(async (file: File) => {
                        return await this.readFileAsTextFromUserSystem(file);
                    })
                )

                resolve(await outputFiles);
            };

            fileInput.oncancel = () => {
                reject("User rejected the file(s) upload");
            }

            fileInput.click();
        });   
    }

    public static convertTextToFiles(files: TUserFiles): File[] {
        const convertedFiles: File[] = [];

        files.forEach((currentFile: UserFile) => {
            const blob = new Blob([currentFile.getContent()], {
                type: "text/javascript",
            });
            const file = new File([blob], currentFile.getFullName(), {
                type: "application/javascript",
            });

            convertedFiles.push(file);
        });

        return convertedFiles;
    }
}
