import TooltipWrapper from "@components/TooltipWrapper";
import ProjectTemplateCard from "@components/ProjectTemplateCard";
import { IProjectTemplateData } from "@panels/ProjectTemplatesPanel/meta";
import { memo, ReactElement } from "react";
import "./style.css";

export interface IProjectTemplatesListProps {
    templates: IProjectTemplateData[];
}

const ProjectTemplatesList = memo(
    ({ templates }: IProjectTemplatesListProps): ReactElement => {
        return (
            <div className="project-templates-list">
                {templates.map((template: IProjectTemplateData) => (
                    <TooltipWrapper
                        key={template.key}
                        isTooltipSizeExpanded={true}
                        tooltipText={template.description}
                    >
                        <ProjectTemplateCard
                            templateCardData={template}
                        />
                    </TooltipWrapper>
                ))}
            </div>
        );
    }
);

export default ProjectTemplatesList;
