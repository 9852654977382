import PreviewAreaPanel from "@panels/PreviewAreaPanel";
import ComponentsLibrary from "@components/ComponentsLibrary";
import ProjectTemplatesPanel from "@panels/ProjectTemplatesPanel";
import { ReactNode } from "react";
import "./style.css";

export interface ITabsData {
    key: string;
    label: string;
    component: ReactNode;
}

export enum DevToolsTabsKeys {
    PREVIEW_AREA = "preview area",
    LIBRARY = "library",
    TEMPLATES = "templates",
}

export const previewTabsMap: Record<string, ITabsData> = {
    [DevToolsTabsKeys.PREVIEW_AREA]: {
        key: "preview area",
        label: "Preview",
        component: <PreviewAreaPanel />,
    },
    [DevToolsTabsKeys.LIBRARY]: {
        key: "library",
        label: "Library",
        component: <ComponentsLibrary />,
    },
    [DevToolsTabsKeys.TEMPLATES]: {
        key: "templates",
        label: "Templates",
        component: <ProjectTemplatesPanel />,
    },
};
