import LabeledCheckbox from "@components/LabeledCheckbox";
import { ReactElement, useState } from "react";
import "./style.css";

export interface IFiltersListProps {
    filters: string[];
    onActiveFiltersListChange: (activeItems: string[]) => void;
}

const FiltersList = ({
    filters,
    onActiveFiltersListChange,
}: IFiltersListProps): ReactElement => {
    const [activeFilters, setActiveFilters] = useState<string[]>([]);

    const handleActiveFiltersChange = (
        filter: string,
        value: boolean
    ): void => {
        const newActiveFilters: string[] = [...activeFilters];

        if (!value) {
            newActiveFilters.splice(newActiveFilters.indexOf(filter), 1);
        } else {
            newActiveFilters.push(filter);
        }        

        setActiveFilters(newActiveFilters);
        onActiveFiltersListChange(newActiveFilters);
    };

    return (
        <div className="filters-list">
            {filters.map((filter) => (
                <LabeledCheckbox
                    key={filter}
                    label={filter}
                    onChange={(value) =>
                        handleActiveFiltersChange(filter, value)
                    }
                />
            ))}
        </div>
    );
};

export default FiltersList;
