import Workspace from "@pages/Workspace";
import NotFoundPage from "@pages/NotFound";
import { RouteObject } from "react-router-dom";

export const paths = {
	HOME: {
		id: "Home",
		path: "/",
        label: "Home",
	},
	NOT_FOUND: {
		id: "notFound",
        path: "*",
        label: "notFound",
	},
}

const routes: RouteObject[] = [
    {
        ...paths.HOME,
        element: <Workspace />,
    },
    {
        ...paths.NOT_FOUND,
        element: <NotFoundPage />,
    },
];

export default routes;
