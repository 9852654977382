import SvgSpriteIcon from "@components/SvgSpriteIcon";
import { useState } from "react";
import "./style.css";

export interface IMenuButtonProps {
    id?: string;
    icon: string;
    label: string;
    onClick: () => void;
}

const DEFAULT_HOVER_CLASS_NAME: string = "-hover";

export const MainMenuButton = ({
    icon,
    label,
    onClick,
}: IMenuButtonProps) => {
    const [hoverClassName, setHoverClassName] = useState<string>(
        DEFAULT_HOVER_CLASS_NAME
    );

    return (
        <div
            onClick={onClick}
            className={`main-menu-button ${icon}`}
            onMouseEnter={() => setHoverClassName(DEFAULT_HOVER_CLASS_NAME)}
            onMouseLeave={() => setHoverClassName("")}
        >
            <SvgSpriteIcon
                className={`main-menu-button-svg`}
                id={`${icon}${hoverClassName}`}
            />
            <p className="main-menu-button-label">{label}</p>
        </div>
    );
};

export default MainMenuButton;
