import CodeEditorPanel from "@panels/CodeEditorPanel";
import DeveloperToolsPanel from "@panels/DeveloperToolsPanel";
import SplitBar, { type TPanelWidth } from "@components/SplitBar";
import { ReactElement, useState } from "react";
import { TRootState } from "@store/index";
import { useSelector } from "react-redux";
import "./style.css";

export const enum SwitchDirections {
    LEFT = "left",
    RIGHT = "right",
}

export const switchScreenSides = (to: SwitchDirections): void => {
    const panels = document.querySelector("#workspace-panels");

    if (typeof panels?.scrollLeft !== "number") {
        return;
    }

    if (to === SwitchDirections.RIGHT) {
        panels.scrollLeft += panels.clientWidth;
        return;
    } 

    if (to === SwitchDirections.LEFT) {
        panels.scrollLeft -= panels.clientWidth;
        return;
    }
};

const WorkspacePanels = (): ReactElement => {
    const [panelsWidth, setPanelsWidth] = useState<TPanelWidth>({
        LEFT: "50%",
        RIGHT: "50%",
    });

    const isTabletMode: boolean = useSelector(
        (state: TRootState) => state.application.isTabletMode
    );

    return (
        <div className="workspace-panels" id="workspace-panels">
            <div
                className="workspace-panel code-editor"
                style={!isTabletMode ? { width: panelsWidth.LEFT } : undefined}
            >
                <CodeEditorPanel />
            </div>
            {!isTabletMode && <SplitBar setPanelWidth={setPanelsWidth} />}
            <div
                className="workspace-panel developer-tools"
                style={!isTabletMode ? { width: panelsWidth.RIGHT } : undefined}
            >
                <DeveloperToolsPanel />
            </div>
        </div>
    );
};

export default WorkspacePanels;
