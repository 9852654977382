const BYTE_MULTIPLIER = 1024;

interface IEventListenerForKey {
    keyValue: KeyboardEvent["key"];
    isCtrlPress: boolean;
    callback: () => void;
}

const eventListenerForKey = ({
    keyValue,
    callback,
    isCtrlPress = false,
}: IEventListenerForKey) => {
    async function callBackCaller(event: KeyboardEvent) {
        if (event?.code === keyValue && event?.ctrlKey === isCtrlPress) {
            await callback();
        }
    }

    return callBackCaller;
};

const byteToMegabyte = (byte: number): number => {
    const megabyteSize = byte / BYTE_MULTIPLIER / BYTE_MULTIPLIER;
    return megabyteSize;
};

export { byteToMegabyte, eventListenerForKey };
