import IdService from "@services/IdService";
export type TUserFileContent = string;
export type TUserFileExtension = string | undefined;
export type TUserFiles = UserFile[];

export interface IUserFileSerializable {
    id: string;
    name: string;
    fullName: string;
    extension: TUserFileExtension;
    isDisplayed: boolean;
    content: TUserFileContent;
}

export default class UserFile {
    private id: string;
    private name: string;
    private fullName: string;
    private extension: TUserFileExtension;
    private isDisplayed: boolean;
    private content: TUserFileContent;

    constructor(
        id: string = IdService.generateUniqueId(),
        name: string = "index",
        extension = "js",
        isDisplayed: boolean = true,
        content: TUserFileContent = "",
    ) {
        this.id = id;
        this.name = name;
        this.extension = extension;
        this.isDisplayed = isDisplayed;
        this.content = content;
        this.fullName = this.createFullName(name, extension);
    }
    
    public setId(id: string): void {
        this.id = id;
    }

    public setName(name: string): void {
        this.name = name;
        this.fullName = this.createFullName(name, this.extension);
    }

    public setExtension(extension: TUserFileExtension): void {
        this.extension = extension;
        this.fullName = this.createFullName(this.name, extension);
    }

    public setIsDisplayed(isDisplayed: boolean): void {
        this.isDisplayed = isDisplayed;
    }

    public setFullName(fullName: string): void {
        this.fullName = fullName;
        this.name = this.getNameFromFullName(fullName);
        this.extension = this.getExtensionFromFullName(fullName);
    }

    public setContent(content: TUserFileContent): void {
        this.content = content;
    }

    public getId(): string {
        return this.id;
    }

    public getName(): string {
        return this.name;
    }

    public getExtension(): TUserFileExtension {
        return this.extension;
    }

    public getIsDisplayed(): boolean {
        return this.isDisplayed;
    }

    public getFullName(): string {
        return this.fullName;
    }

    public getContent(): TUserFileContent {
        return this.content;
    }

    private createFullName(
        name: string,
        extension: TUserFileExtension
    ): string {
        if (!extension) {
            return name;
        }
        return `${name}.${extension}`;
    }

    private getNameFromFullName(fullName: string): string {
        return fullName.split(".")[0];
    }

    private getExtensionFromFullName(fullName: string): TUserFileExtension {
        return fullName.split(".")[1];
    }
}
