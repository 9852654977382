import FilesActionsToolbar from "@components/FilesActionsToolbar";
import CodeEditorSettingsToolbar from "@components/CodeEditorSettingsToolbar"
import { ReactElement } from "react";
import "./style.css";

const CodeEditorToolbars = (): ReactElement => {
    return (
        <div className="code-editor-toolbars">
            <CodeEditorSettingsToolbar />
            <FilesActionsToolbar />
        </div>
    );
}

export default CodeEditorToolbars;