import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { defaultTheme, themeKey, Themes } from "@domains/Theme";
import LocalStorageService from "@services/localStorageService";

export interface IApplicationSlice {
    isMainMenuDisplayed: boolean;
    isMainMenuDisplayedAtFirst: boolean;
    isTabletMode: boolean;
    isLoading: boolean;
    currentTheme: Themes;
}

const initialState: IApplicationSlice = {
    isTabletMode: false,
    isMainMenuDisplayed: true,
    isMainMenuDisplayedAtFirst: true,
    isLoading: false,
    currentTheme: defaultTheme,
};

export const applicationSlice: Slice<IApplicationSlice> = createSlice({
    name: "application",
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsTabletMode: (state, action: PayloadAction<boolean>) => {
            state.isTabletMode = action.payload;
        },
        setIsMainMenuDisplayed: (state, action: PayloadAction<boolean>) => {
            state.isMainMenuDisplayed = action.payload;
        },
        setIsMainMenuDisplayedAtFirst: (state, action: PayloadAction<boolean>) => {
            state.isMainMenuDisplayedAtFirst = action.payload;
        },
        setCurrentTheme: (state, action: PayloadAction<Themes>) => {
            state.currentTheme = action.payload;
            LocalStorageService.setValue(themeKey, action.payload);
        },
    },
});

export const {
    setIsLoading,
    setCurrentTheme,
    setIsTabletMode,
    setIsMainMenuDisplayed,
    setIsMainMenuDisplayedAtFirst
} = applicationSlice.actions;

export default applicationSlice.reducer;
