import React from "react";
import AlertBox from ".";

const alertBoxData = {
    component: <AlertBox message={`This is AlertBox!`} type={`info`} />,
    import: "import AlertBox from '@integratedComponents/AlertBox';",
    usage: `<AlertBox\n\tmessage={'Type your message'}\n\ttype={'info'}\n/>`,
    props: {
        message: `The main text displayed within the AlertBox component.\n\nThis is the content that communicates information to the user.\n\nExample:\n\tmessage={'This is an important alert!'}\n\n`,
        type: `Specifies the type of alert to determine its style and icon (if any).\n\nOptions include:\n\t- "error" — displays an error alert\n\t- "success" — displays a success alert\n\t- "warning" — displays a warning alert\n\t- "info" — displays an informational alert\n\nExample:\n\ttype={'info'}\n\n`,
    },
};

export default alertBoxData;
