import { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import snetValidator from "@utils/functions/snetValidator";
import { forgotPassworSubmitConstraints } from "./validationConstraints";
import AlertText from "@components/ComponentsLibrary/components/AlertText";
import AuthorizationService from "@services/AuthorizationService";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "@store/index";
import { setIsLoading } from "@store/slices/application";
import Timer from "@components/Timer";
import { InputAdornment } from "@mui/material";
import { eventListenerForKey } from "@utils/functions";
import useNotifications from "@hooks/useNotifications";
import { AlertTypes } from "@domains/Alert";

export interface IForgotPassword {
    setSignInForm: () => void
}

const ForgotPasswordConfirm = ({setSignInForm}: IForgotPassword) => {
    const dispatch = useDispatch();
    const { createAlert } = useNotifications();
    const storeEmail = useSelector((state: TRootState) => state.authorization.user.email)

    const [email, setEmail] = useState<string>(storeEmail);
    const [code, setCode] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [validationErrors, setValidationErrors] = useState<string[] | undefined>();
    const [resendButtonDisabled, setResendButtonDisabled] = useState<boolean>(false);

    const isFilledAllFields: boolean = !!code &&  !!email && !!password && !!confirmPassword;

    const isFormValid = useCallback((): boolean => {
        const isNotValid = snetValidator({ password, confirmPassword, code, email }, forgotPassworSubmitConstraints);
        
        if (isNotValid) {
            setValidationErrors(isNotValid);
            return false;
        }
        setValidationErrors(undefined);
        return true;
    }, [password, confirmPassword, code, email]);

    useEffect(()=>{
        if (!isFilledAllFields) {
            return;
        }
        isFormValid();
    }, [email, code, password, confirmPassword, isFilledAllFields, isFormValid]);

    const handleSubmit = async () => {
        try {
            dispatch(setIsLoading(true));
            await AuthorizationService.confirmResetPassword({email, password, verificationCode: code});
            setSignInForm();
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Something went wrong!",
                message: error?.message,
            });
        } finally {
            dispatch(setIsLoading(false));
        };
    };

    const handleResend = async () => {
        try {
            dispatch(setIsLoading(true));
            await AuthorizationService.resetPassword({email: storeEmail});
            setResendButtonDisabled(true);
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Something went wrong!",
                message: error?.message,
            });
        } finally {
            dispatch(setIsLoading(false));
        };
    }

    const listener = eventListenerForKey({keyValue: "Enter", callback: handleSubmit, isCtrlPress: true});
    
    useEffect (()=>{
        if (isFormValid()) {
            document.addEventListener('keypress', listener, false);
        } 
        return () => document.removeEventListener('keypress', listener, false);
    }, [isFormValid, listener]);
        

    const confirmFieldsData = [
        {
            id: "outlined-email-input",
            label: "Email",
            type: "email",
            name: "email",
            value: email,
            onChange: setEmail,
        },
        {
            id: "outlined-code-input",
            label: "Code",
            type: "code",
            name: "code",
            value: code,
            onChange: setCode,
        },
        {
            id: "outlined-new-password-input",
            label: "New Password",
            type: "password",
            name: "password",
            margin: "normal",
            value: password,
            onChange: setPassword,
        },
        {
            id: "outlined-confirm-password-input",
            label: "Confirm Password",
            type: "password",
            name: "password",
            value: confirmPassword,
            onChange: setConfirmPassword,
        },
    ];

    return (
        <form className="forgot-password-form">
            {confirmFieldsData.map((field) => (
                <TextField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    name={field.name}
                    variant="outlined"
                    value={field.value}
                    required
                    onChange={(e) => field.onChange(e.target.value)}
                    InputProps={{
                        startAdornment:( <InputAdornment className="dump-for-focus-fields" position="start"/>)
                    }}
                />
            ))}
            <div className="errors-container">
                {!!validationErrors && validationErrors?.map(validationError => (
                    <AlertText key={validationError} message={validationError} />
                ))}
            </div>
            <div className="buttons-container">
                <Button
                    color="primary"
                    variant="outlined"
                    children={
                        !resendButtonDisabled ?
                        "resend code"
                        : <Timer endTime={40} handleTimerCompletion={() => setResendButtonDisabled(false)} interval={1}/>
                    }
                    disabled={resendButtonDisabled}
                    onClick={handleResend}
                />
                <Button
                    color="primary"
                    variant="contained"
                    children="reset password"
                    disabled={!!validationErrors || !isFilledAllFields}
                    onClick={handleSubmit}
                />
                <Button
                    color="error"
                    variant="contained"
                    children="cancel"
                    onClick={setSignInForm}
                />
            </div>
        </form>
    );
};

export default ForgotPasswordConfirm;
