import UserFile from "@domains/File";
import useNotifications from "@hooks/useNotifications";
import FileExplorerContext from "@context/FileExplorerContext";
import { useContext, type ChangeEvent, type ReactElement } from "react";
import { type MouseEvent, type FormEvent } from "react";
import { AlertTypes } from "@domains/Alert";
import { useState } from "react";
import "./style.css";
import FilesService from "@services/FilesService";
export interface IFileTabsRenamerProps {
    file: UserFile;
    handleCloseRename: () => void;
}

const FileTabsRenamer = ({
    file,
    handleCloseRename,
}: IFileTabsRenamerProps): ReactElement => {
    const { createAlert } = useNotifications();
    const { updateFile, filesList } = useContext(FileExplorerContext);
    const [fileName, setFileName] = useState<string>(file.getFullName());
    
    const handleRenameSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (!fileName) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "File error",
                message: "The name of file can not be empty!"
            });

            handleCloseRename();
            return;
        }

        if (file.getFullName() === fileName) {
            handleCloseRename();
            return;
        }

        file.setFullName(fileName);

        const newFiles = FilesService.excludeFileFromFilesList(file, filesList);
        const newFile = FilesService.processFileNameDuplicates(file, newFiles);

        updateFile(newFile);
        handleCloseRename();
    };

    const handleFormCLick = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
    };

    const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
        event.stopPropagation();
        setFileName(event.target.value);
    };

    return (
        <form onSubmit={handleRenameSubmit} onClick={handleFormCLick}>
            <input value={fileName} onInput={handleInput} autoFocus />
            <button type="submit">OK</button>
            <button onClick={handleCloseRename}>CANCEL</button>
        </form>
    );
};

export default FileTabsRenamer;
