import CloseButton from "@components/CloseButton";
import useNotifications from "@hooks/useNotifications";
import WorkspaceContext from "@context/WorkspaceContext";
import FileExplorerContext from "@context/FileExplorerContext";
import ModalWindow, { ModalTypes } from "@components/ModalWindow";
import MainMenuButton, { IMenuButtonProps } from "@components/MainMenuButton";
import {
    SwitchDirections,
    switchScreenSides,
} from "@components/WorkspacePanels";
import { DevToolsTabsKeys } from "@panels/DeveloperToolsPanel/meta";
import { StartTemplates } from "@panels/ProjectTemplatesPanel/meta";
import { useContext, useEffect, type ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogButtonsLabels } from "@domains/Dialog";
import { TRootState } from "@store";
import {
    setIsMainMenuDisplayed,
    setIsMainMenuDisplayedAtFirst,
} from "@store/slices/application";
import "./style.css";

const MainMenu = (): ReactNode => {
    const dispatch = useDispatch();

    const { createDialog } = useNotifications();

    const {
        openProject: openSavedProject,
        openProjectTemplate,
        deleteFiles,
    } = useContext(FileExplorerContext);

    const { setDeveloperToolsTab } = useContext(WorkspaceContext);

    const isMainMenuDisplayed = useSelector(
        (state: TRootState) => state.application.isMainMenuDisplayed
    );

    const isMainMenuDisplayedAtFirst = useSelector(
        (state: TRootState) => state.application.isMainMenuDisplayedAtFirst
    );

    const isTabletMode: boolean = useSelector(
        (state: TRootState) => state.application.isTabletMode
    );

    const closeMenu = (): void => {
        dispatch(setIsMainMenuDisplayed(false));
        dispatch(setIsMainMenuDisplayedAtFirst(false));
    };

    const createNewProject = async (): Promise<void> => {
        await openProjectTemplate(StartTemplates.DRAFT);

        isTabletMode && switchScreenSides(SwitchDirections.LEFT);

        closeMenu();
    };

    const openProject = async (): Promise<void> => {
        await openSavedProject();

        isTabletMode && switchScreenSides(SwitchDirections.LEFT);

        closeMenu();
    };

    const openTemplates = (): void => {
        deleteFiles();

        setDeveloperToolsTab(DevToolsTabsKeys.TEMPLATES);

        isTabletMode && switchScreenSides(SwitchDirections.RIGHT);

        closeMenu();
    };

    const openTemplatesScenario = (): void => {
        if (!isMainMenuDisplayedAtFirst) {
            createDialog({
                title: "Hands up!",
                message:
                    "Are you sure you want to continue? All unsaved data will be lost.",
                submitLabel: DialogButtonsLabels.PROCEED,
                cancelLabel: DialogButtonsLabels.CANCEL,
                submitAction: openTemplates,
            });

            return;
        }

        openTemplates();
    };

    const openExample = async (): Promise<void> => {
        await openProjectTemplate(StartTemplates.EXAMPLE_SERVICE);
        
        isTabletMode && switchScreenSides(SwitchDirections.LEFT);

        closeMenu();
    };

    const menuButtonsData: IMenuButtonProps[] = [
        {
            id: "new-project",
            icon: "new-project",
            label: "New project",
            onClick: createNewProject,
        },
        {
            id: "open-project",
            icon: "open-project",
            label: "Open project",
            onClick: openProject,
        },
        // {
        //     id: "restore-project",
        //     icon: "restore-project-button",
        //     label: "Restore project",
        //     onClick: restoreFromCache,
        // },
        {
            id: "template-project",
            icon: "open-templates",
            label: "Open Templates",
            onClick: openTemplatesScenario,
        },
        {
            id: "example-project",
            icon: "open-example",
            label: "Open example",
            onClick: openExample,
        },
    ];

    useEffect(() => {
        openProjectTemplate(StartTemplates.EXAMPLE_SERVICE, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isMainMenuDisplayed) {
        return null;
    }

    const modalType = !isMainMenuDisplayedAtFirst
        ? ModalTypes.DEFAULT
        : ModalTypes.SCREEN_LOCKER;

    return (
        <ModalWindow type={modalType} onCLick={closeMenu}>
            <div className="main-menu">
                {!isMainMenuDisplayedAtFirst && (
                    <CloseButton onClick={closeMenu} />
                )}
                <div className="main-menu-buttons">
                    {menuButtonsData.map((buttonData) => (
                        <MainMenuButton
                            key={buttonData.id}
                            icon={buttonData.icon}
                            label={buttonData.label}
                            onClick={buttonData.onClick}
                        />
                    ))}
                </div>
            </div>
        </ModalWindow>
    );
};

export default MainMenu;
