import pagesMeta from "./meta";
import { createBrowserRouter } from "react-router-dom";

export const MainRouter = createBrowserRouter(pagesMeta, {
    future: {
        v7_relativeSplatPath: true,
    },
});

const routerProps = {
    router: MainRouter,
    future: {
        v7_startTransition: true,
    },
};

export default routerProps;
