import React from "react";
import AlertText from ".";

const alertTextData = {
    component: <AlertText message={`This is AlertText!`} type={`info`} />,
    import: "import AlertText from '@integratedComponents/AlertText';",
    usage: `<AlertText\n\tmessage={'Type your message'}\n\ttype={'info'}\n/>`,
    props: {
        message: `The text message to display within the alert.\n\nExample:\n\tmessage={'This is an alert message!'}\n\n`,
        type: `Defines the alert type, which affects the styling and visual indication.\n\nAllowed values:\n\t- "error" — indicates an error message\n\t- "success" — indicates a success message\n\t- "warning" — indicates a warning message\n\t- "info" — indicates an informational message\n\nExample:\n\ttype={'info'}\n\n`,
    },
};

export default alertTextData;
