import useNotifications from "@hooks/useNotifications";
import snetValidator from "@utils/functions/snetValidator";
import FileUploader from "@components/ComponentsLibrary/components/FileUploader";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { publishFeedbackAssets } from "@api/publishFilesApi";
import { setIsLoading } from "@store/slices/application";
import { eventListenerForKey } from "@utils/functions";
import { byteToMegabyte } from "@utils/functions";
import { useDispatch } from "react-redux";
import { isEmpty } from "validate.js";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import {
    acceptedFileTypes,
    feedbackCategories,
    formFieldsMeta,
    IFeedbackForm,
    IFormFieldMeta,
    IFormFields,
    initialFormState,
    MAX_FILE_SIZE,
    textAreaField,
} from "./meta";
import "./styles.css";
import { AlertTypes } from "@domains/Alert";

const FeedbackForm = ({ closeForm }: IFeedbackForm) => {
    const dispatch = useDispatch();
    const { createAlert } = useNotifications();

    const [formFields, setFormFields] = useState<IFormFields>(initialFormState);
    const [category, setCategory] = useState("");
    const [isRequestHandling, setIsRequestHandling] = useState<boolean>(false);
    const [isSubmitAvailable, setIsSubmitAvailable] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [uploadedFilesUrl, setUploadedFilesUrl] = useState<string[]>([]);

    const sendFeedback = async () => {
        try {
            dispatch(setIsLoading(true));
            const options: RequestInit = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify({
                    source: "UI_CONSTRUCTOR",
                    name: formFields.name,
                    address: "",
                    email: formFields.email,
                    phone_no: "",
                    message_type: category,
                    subject: "",
                    message: formFields.feedback,
                    org_id: formFields?.orgId,
                    service_id: formFields?.serviceId,
                    endpoint: formFields?.endpoint,
                    attachment_details: {},
                    attachment_urls: uploadedFilesUrl,
                }),
            };
            setIsRequestHandling(true);
            const feedbackUrl: string | undefined =
                process?.env?.REACT_APP_FEEDBACK_ENDPOINT;
            if (!feedbackUrl) {
                throw new Error(
                    "Cannot start the application! process.env.REACT_APP_FEEDBACK_ENDPOINT is undefined"
                );
            }
            await fetch(feedbackUrl + "/user/message", options);
            createAlert({
                type: AlertTypes.SUCCESS,
                title: "Success!",
                message: "Our technical support will get in touch with you soon!",
            });
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Error",
                message: error?.message,
            });
        } finally {
            resetForm();
            setIsRequestHandling(false);
            dispatch(setIsLoading(false));
            closeForm();
        }
    };

    const listener = eventListenerForKey({
        keyValue: "Enter",
        callback: sendFeedback,
        isCtrlPress: true,
    });

    useEffect(() => {
        if (isSubmitAvailable) {
            document.addEventListener("keypress", listener, false);
        }
        return () => document.removeEventListener("keypress", listener, false);
    }, [isSubmitAvailable, listener]);

    const invalidMessageByField: { [index: string]: string | undefined } =
        useMemo(
            () => ({
                email: snetValidator.validators.validEmail(formFields.email),
                name: !formFields.name ? "name is empty" : undefined,
                feedback: !formFields.feedback
                    ? "feedback is empty"
                    : undefined,
                category: !category ? "category is empty" : undefined,
            }),
            [formFields.email, formFields.name, formFields.feedback, category]
        );

    const invalidMessageByFieldArray = useMemo(
        () => Object.values(invalidMessageByField),
        [invalidMessageByField]
    );
    const isFieldMessageEmty = useMemo(
        () =>
            invalidMessageByFieldArray.reduce(
                (accumulator, invalidMessage) => accumulator && !invalidMessage,
                true
            ),
        [invalidMessageByFieldArray]
    );

    const checkIsSubmitAvailable = useCallback((): void => {
        setIsSubmitAvailable(isFieldMessageEmty && !isRequestHandling);
    }, [isFieldMessageEmty, isRequestHandling]);

    useEffect(() => {
        checkIsSubmitAvailable();
    }, [
        formFields.name,
        formFields.feedback,
        category,
        checkIsSubmitAvailable,
    ]);

    const resetForm = () => {
        setFormFields(initialFormState);
    };

    const SelectCategory = () => {
        return (
            <FormControl fullWidth>
                <InputLabel id="select-label">Select a category</InputLabel>
                <Select
                    labelId="select-label"
                    id="select"
                    value={category}
                    label="Select a category"
                    onChange={(event: SelectChangeEvent) => {
                        setCategory(event.target.value as string);
                    }}
                >
                    {feedbackCategories.map((feedbackCategory) => (
                        <MenuItem
                            key={feedbackCategory.value}
                            value={feedbackCategory.value}
                        >
                            {feedbackCategory.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const fieldInvalidMessage = (formField: IFormFieldMeta) => {
        if (formField?.isValidate && Boolean(formFields[formField.id])) {
            const invalidMessage = invalidMessageByField[formField.id];
            return invalidMessage;
        }
        return null;
    };

    const onInputFormField = (
        event: ChangeEvent<HTMLInputElement>,
        formFieldId: keyof IFormFields
    ) => {
        setFormFields({ ...formFields, [formFieldId]: event.target.value });
    };

    const handleDrop = async (acceptedFiles: File[], rejectedFiles: File[]) => {
        if (!isEmpty(rejectedFiles)) {
            return;
        }

        if (!formFields.email) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Error",
                message: "Please, fill your email",
            });

            return;
        }

        if (isEmpty(acceptedFiles)) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Error",
                message: "Accepted files were not found",
            });

            return;
        }

        try {
            dispatch(setIsLoading(true));
            const acceptedFilesUrl = await Promise.all(
                acceptedFiles.map(async (acceptedFile) => {
                    const { name, size } = acceptedFile;
                    if (byteToMegabyte(size) > MAX_FILE_SIZE) {
                        createAlert({
                            type: AlertTypes.ERROR,
                            title: `The ${name} is too big`,
                            message: `The file should be no more than ${MAX_FILE_SIZE}Mb`,
                        });

                        throw new Error(`The ${name} is too big`);
                    }
                    const { url } = await publishFeedbackAssets(
                        acceptedFile,
                        name,
                        formFields?.email
                    );
                    return url;
                })
            );
            setUploadedFiles(acceptedFiles);
            setUploadedFilesUrl(acceptedFilesUrl);
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "File upload error",
                message: error?.message,
            });

            setUploadedFilesUrl([]);
            setUploadedFiles([]);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    return (
        <div className="feedback-form-holder feedback-form">
            <div className="form-header">
                <h2>Feedback form</h2>
            </div>
            <form className="feedback-form">
                <div className="feedback-form-fields">
                    <SelectCategory />
                    {Object.values(formFieldsMeta).map((formField) => (
                        <TextField
                            key={formField.id}
                            id={formField.id}
                            value={formFields[formField.id]}
                            variant="outlined"
                            required={formField.isRequired}
                            label={formField.label}
                            placeholder={formField.placeholder}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                onInputFormField(event, formField.id);
                            }}
                            error={!!fieldInvalidMessage(formField)}
                            helperText={fieldInvalidMessage(formField)}
                        />
                    ))}
                    <p className="describe-optional-text">
                        If your question is about the code, then attach the
                        archive and data of your organization to speed up the
                        support process
                    </p>
                    <TextField
                        key={textAreaField.id}
                        id={textAreaField.id}
                        value={formFields[textAreaField.id]}
                        variant="outlined"
                        required={textAreaField.isRequired}
                        label={textAreaField.label}
                        placeholder={textAreaField.placeholder}
                        multiline
                        minRows={textAreaField.minRows}
                        maxRows={textAreaField.maxRows}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            onInputFormField(event, textAreaField.id);
                        }}
                    />
                    <div className="assets-uploader-container">
                        <div className="assets-uploader-helper">
                            <p>
                                Please fill out the email before sending the
                                attachments
                            </p>
                            <p>
                                The files should be no more than {MAX_FILE_SIZE}
                                Mb
                            </p>
                        </div>
                        <FileUploader
                            handleFileUpload={handleDrop}
                            fileAccept={acceptedFileTypes}
                            multiple={true}
                            uploadedFiles={uploadedFiles}
                        />
                    </div>
                </div>
                <div className="submit-btn-container">
                    <Button
                        className="cancel-button"
                        variant="outlined"
                        onClick={closeForm}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="submit-button"
                        variant="contained"
                        disabled={!isSubmitAvailable}
                        onClick={sendFeedback}
                    >
                        <span>Confirm</span>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default FeedbackForm;
