import SvgSpriteIcon from "@components/SvgSpriteIcon";
import { type ReactElement } from "react";
import { Link } from "react-router-dom";
import { paths } from "@router/meta";
import "./style.css";

const MainLogo = (): ReactElement => {
    return (
        <div className="logo-container">
            <Link to={paths.HOME.path}>
                <SvgSpriteIcon className={"main-logo"} id={"main-logo"} />
            </Link>
        </div>
    );
};

export default MainLogo;
