import { Accept } from "react-dropzone/.";

export const formFieldsMeta: IFormFieldMeta[]  = [
    {id: 'name', label: 'Name', isRequired: true, placeholder: 'Enter your name'},
    {id: 'email', label: 'Email', isRequired: true, placeholder: 'Enter your email', isValidate: true},
    {id: 'orgId', label: 'Organization ID (optional)', isRequired: false, placeholder: 'Enter the organization ID',},
    {id: 'serviceId', label: 'Service ID (optional)', isRequired: false, placeholder: 'Enter the service ID',},
    {id: 'endpoint', label: 'Endpoint (optional)', isRequired: false, placeholder: 'Enter the endpoint'},
]

export const textAreaField: IFormFieldMeta = {
    id: 'feedback', label: 'Feedback', isRequired: false, placeholder: 'Enter your feedback', minRows: 1, maxRows: 4
}

export const feedbackCategories = [
    { value: "question", title: "Question" },
    { value: "bug", title: "Bug" },
    { value: "feedback", title: "Feedback" },
]

export const acceptedFileTypes: Accept = {
    "application/zip": [".zip"],
    "application/x-zip-compressed": [".zip"],
    "image/jpeg": [".jpeg"],
    "image/png": [".png"],
    "file/proto": [".proto"]
};

export const MAX_FILE_SIZE = 5; // Mb

export interface IFeedbackForm {
    closeForm: () => void;
}

export interface IFormFieldMeta  {
    id: keyof IFormFields;
    label: string;
    isRequired: boolean;
    placeholder: string;
    minRows?: number;
    maxRows?: number;
    isValidate?: boolean;
}

export interface IFormFields {
    name: string;
    email: string;
    serviceId: string;
    orgId: string;
    feedback: string;
    endpoint: string;
}

export const initialFormState: IFormFields = {
    name: "",
    email: "",
    serviceId: "",
    orgId: "",
    feedback: "",
    endpoint: "",  
};