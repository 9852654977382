import UserFile from "@domains/File";
import FileTabsRenamer from "@components/FileTabRenamer";
import FileTabButtons from "@components/FileTabButtons";
import useAlertsFactory from "@hooks/useNotifications";
import {
    type ReactElement,
    type MouseEvent,
    useState,
    useContext,
} from "react";
import { DialogButtonsLabels } from "@domains/Dialog";
import { AlertTypes } from "@domains/Alert";
import "./style.css";
import FileExplorerContext from "@context/FileExplorerContext";

export interface IFileTabProps {
    tabId?: string;
    file: UserFile;
    filesCount: number;
    isActive?: boolean;
    onClick?: () => void;
}

const FileTab = ({
    tabId,
    file,
    filesCount,
    isActive,
    onClick,
}: IFileTabProps): ReactElement => {
    const { createDialog, createAlert } = useAlertsFactory();
    // const { createDialog, /* createAlert */ } = useAlertsFactory();
    const { deleteFile } = useContext(FileExplorerContext);
    const [isTabRenaming, setIsTabRenaming] = useState<boolean>(false);

    const handleFileDelete = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();

        const deleteActions = () => {
            if (filesCount === 1) {
                createAlert({
                    type: AlertTypes.ERROR,
                    title: "Application error",
                    message: "You can not delete the last file!",
                });
                return;
            }

            deleteFile(file);
        };

        createDialog({
            title: "Confirm file deletion",
            message: `Do you really want to delete ${file.getFullName()}?`,
            submitLabel: DialogButtonsLabels.DELETE,
            cancelLabel: DialogButtonsLabels.CANCEL,
            // submitAction: () => deleteFile(file),
            submitAction: deleteActions,
        });
    };

    const handleRenameTriggered = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setIsTabRenaming(true);
        document.addEventListener("click", handleCloseRename);
    };

    const handleCloseRename = (): void => {
        setIsTabRenaming(false);
        document.removeEventListener("click", handleCloseRename);
    };

    const FileTabName = (): ReactElement => {
        if (!isTabRenaming) {
            return (
                <div className="file-tab-name" title={file.getFullName()}>
                    {file.getFullName()}
                </div>
            );
        }
        return (
            <FileTabsRenamer
                file={file}
                handleCloseRename={handleCloseRename}
            />
        );
    };

    const classname: string = `file-tab ${isActive ? "selected" : ""} ${
        isTabRenaming ? "renaming" : ""
    }`;

    return (
        <div onClick={onClick} id={`tab-${tabId}`} className={classname}>
            <div className="file-tab-body">
                <FileTabName />
                {!isTabRenaming && (
                    <FileTabButtons
                        onDelete={handleFileDelete}
                        onRename={handleRenameTriggered}
                    />
                )}
            </div>
        </div>
    );
};

export default FileTab;
