import SvgSpriteIcon from "@components/SvgSpriteIcon";
import CompileButton from "@components/CompileButton";
import CodeCompilerContext from "@context/CodeCompilerContext";
import FileExplorerContext from "@context/FileExplorerContext";
import { ReactElement, useContext } from "react";
import "./style.css";

const PreviewAreaStub = (): ReactElement => {
    const { filesList } = useContext(FileExplorerContext);
    const { compilePreview } = useContext(CodeCompilerContext);

    const compile = (): void => {
        compilePreview(filesList);
    };

    return (
        <div className="stub-container">
            <div className="stub-icon">
                <SvgSpriteIcon
                    className={"singularity-stub-icon"}
                    id={"singularity-stub-icon"}
                />
            </div>
            <div className="stub-info">
                <div className="stub-title">Nothing here yet...</div>
                <div className="stub-body">
                    <div className="stub-body-line">
                        <span className="stub-span">Press</span>
                        <CompileButton
                            isDisabled={false}
                            isCompiling={false}
                            onClick={compile}
                        />
                        <span className="stub-span">to run</span>
                    </div>
                    <div className="stub-body-line">preview</div>
                </div>
            </div>
        </div>
    );
};

export default PreviewAreaStub;
