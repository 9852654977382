import MainMenu from "@components/MainMenu";
import ActionsBar from "@components/ActionsBar";
import useFileExplorer from "@hooks/useFileExplorer";
import WorkspaceLayout from "@layouts/WorkspaceLayout";
import FileExplorerContext from "@context/FileExplorerContext";
import WorkspacePanels from "@components/WorkspacePanels";
import usePreviewCompiler from "@hooks/usePreviewCompiler";
import FileExplorerConfig from "@config/FileExplorerConfig";
import CodeCompilerContext from "@context/CodeCompilerContext";
import { type ReactElement } from "react";
import "./style.css";
import WorkspaceContext from "@context/WorkspaceContext";
import useWorkspace from "@hooks/useWorkspace";

const Workspace = (): ReactElement => {
    return (
        <FileExplorerContext.Provider
            value={useFileExplorer(FileExplorerConfig)}
        >
            <WorkspaceContext.Provider value={useWorkspace()}>
                <WorkspaceLayout>
                    <CodeCompilerContext.Provider value={usePreviewCompiler()}>
                        <MainMenu />
                        <WorkspacePanels />
                        <ActionsBar />
                    </CodeCompilerContext.Provider>
                </WorkspaceLayout>
            </WorkspaceContext.Provider>
        </FileExplorerContext.Provider>
    );
};

export default Workspace;
