import store from "@store";
import App from "@components/Application";
import setupResizeObserver from "@domains/ResizeObserver";
import ReactDOM, { type Root } from "react-dom/client";
import { Provider } from "react-redux";

const root: Root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

setupResizeObserver();

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
