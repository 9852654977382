import { ReactElement, ReactNode } from "react";
import "./style.css";

export interface ITabProps {
    children: ReactElement | ReactNode | string;
    isActive: boolean;
    onCLick: () => void;
}

const Tab = ({ children, isActive, onCLick }: ITabProps): ReactElement => {
    const tabClassName: string = `tab ${!isActive ? "" : "active"}`;

    return (
        <div className={tabClassName} onClick={onCLick}>
            {children}
        </div>
    );
};

export default Tab;
