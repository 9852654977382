import { ChangeEvent, ReactElement, useState } from "react";
import "./style.css";

export interface ISearchBarProps {
    onInput: (value: string) => void;
}

const SearchBar = ({ onInput }: ISearchBarProps): ReactElement => {
    const [query, setQuery] = useState<string>("");

    const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
        setQuery(event.target.value);
        onInput(event.target.value.toLowerCase().trim());
    };

    return (
        <div className="search-bar">
            <input
                type="text"
                value={query}
                placeholder="Search"
                onChange={handleInput}
            />
        </div>
    );
};

export default SearchBar;
