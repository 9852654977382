import CodeCompilerContext from "@context/CodeCompilerContext";
import { memo, ReactNode, useContext, useEffect } from "react";

const ServiceApplication = memo((): ReactNode => {
    const { previewSourceUrl, setCompilationErrors } =
        useContext(CodeCompilerContext);

    try {
        const runApplication = async () => {
            import(/* webpackIgnore: true */ previewSourceUrl);
        };

        useEffect(() => {
            if (!previewSourceUrl) {
                return;
            }

            runApplication();
            // eslint-disable-next-line
        }, [previewSourceUrl]);

        return <div id="root1"></div>;
    } catch (error: any) {
        setCompilationErrors(["Preview execution failed", error.message]);
    }
});

export default ServiceApplication;
