import SvgSpriteIcon from "@components/SvgSpriteIcon";
import useNotifications from "@hooks/useNotifications";
import FileExplorerContext from "@context/FileExplorerContext";
import { IProjectTemplateData } from "@panels/ProjectTemplatesPanel/meta";
import { DialogButtonsLabels } from "@domains/Dialog";
import { ReactElement, useContext } from "react";
import "./style.css";

export interface IProjectTemplateCardProps {
    templateCardData: IProjectTemplateData;
}

const ProjectTemplateCard = ({
    templateCardData,
}: IProjectTemplateCardProps): ReactElement => {
    const { openProjectTemplate } = useContext(FileExplorerContext);
    const { createDialog } = useNotifications();

    const uploadTemplate = (): void => {
        createDialog(
            {
                title: "Hands up!",
                message: `Are you sure you want to open ${templateCardData.title} template?\n\nAll current unsaved data will be lost.`,
                submitLabel: DialogButtonsLabels.PROCEED,
                cancelLabel: DialogButtonsLabels.CANCEL,
                submitAction: () => openProjectTemplate(templateCardData.fileName),
            }
        );
    }
    return (
        <div className="project-template-card" onClick={uploadTemplate}>
            <div className="project-template-card-visual-content">
                <div className="project-template-card-icon">
                    <SvgSpriteIcon
                        className="template-data-type-icon"
                        id={templateCardData.sourceTemplateTypeIconName}
                    />
                </div>
                <div className="project-template-card-icon">
                    <SvgSpriteIcon
                        id="templates-arrow"
                        className="template-arrow-icon"
                    />
                </div>
                <div className="project-template-card-icon">
                    <SvgSpriteIcon
                        className="template-data-type-icon"
                        id={templateCardData.targetTemplateTypeIconName}
                    />
                </div>
            </div>
            <p className="project-template-card-title">
                {templateCardData.title}
            </p>
        </div>
    );
};

export default ProjectTemplateCard;
