import SvgSpriteIcon from "@components/SvgSpriteIcon";
import { ReactElement } from "react";
import "./style.css";

const NoResultsPanel = (): ReactElement => {
    return <div className="no-results-panel">
        <div className="no-results-info-block">
            <SvgSpriteIcon id="no-results" className="not-found-icon" />
            <p>No results...</p>
        </div>
    </div>
}

export default NoResultsPanel;