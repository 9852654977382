import { TUserData } from "@domains/User";
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export interface IAuthorizationSlice {
    isUserAuthorized: boolean;
    token: string;
    user: TUserData;
}

const initialState: IAuthorizationSlice = {
    isUserAuthorized: false,
    token: "",
    user: {
        nickname: "",
        email: "",
    }
};

export const authorizationSlice: Slice<IAuthorizationSlice> = createSlice({
    name: "application",
    initialState,
    reducers: {
        setIsUserAuthorized: (state, action: PayloadAction<boolean>) => {
            state.isUserAuthorized = action.payload;
        },
        setUser: (state, action: PayloadAction<TUserData> ) => {
            state.user = action.payload;
        },
        setUserEmail: (state, action: PayloadAction<string> ) => {
            state.user = {...state.user, email: action.payload};
        },
        setAuthorizationToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        resetSession: (state, action: PayloadAction<boolean>) => {
            state.isUserAuthorized = false;
            state.token = "";
            state.user = {
                nickname: "",
                email: "",
            }
        }
    },
});

export const {
    setIsUserAuthorized,
    setUser,
    setUserEmail,
    setAuthorizationToken,
    resetSession
} = authorizationSlice.actions;

export default authorizationSlice.reducer;
