export const FAQ_LOCATION: string | undefined =
    process?.env?.REACT_APP_DEV_PORTAL_SANDBOX_PAGE;

export const MIN_PANEL_WIDTH: number = 25; // %
export const LINE_HEIGHT_PERCENTAGE_MULTIPLIER: number = 100;
export const TABLET_MODE_BREAKPOINT: number = 1100; // px

export const enum SettingsUnits {
    PERCENT = "%",
    PIXELS = "px",
    MINUTES = "min",
}

export const enum LocalStorageEditorSettingsKeys {
    LINE_HEIGHT = "editorLineHeight",
    FONT_SIZE = "editorFontSize",
}

export const MonacoScrollBarSettings = {
    verticalScrollbarSize: 10,
    horizontalScrollbarSize: 10,
    verticalSliderSize: 8,
    horizontalSliderSize: 8,
};

export const enum FileIOErrorsTypes {
    TEMPLATE = "Template error",
    UPLOAD = "Upload Error",
    EXPORT = "Export Error",
}

export const enum FileIOErrors {
    NO_FILES_FOUND = "No Files found",
    NO_UNZIPPED_FILES = "Cannot unpack the .zip data",
    NOT_INDEX_FILE_EXISTS_ERROR = "The project must contain a file named 'index.js'",
    FOLDER_EXISTS_ERROR = "The archive must not contain folders!",
    EMPTY_FILE_ERROR = "'index.js' should not be empty!",
};


