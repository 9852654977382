import ModalWindow, { ModalTypes } from "@components/ModalWindow";
import FullScreenLoader from "@components/FullScreenLoader";
import FeedbackForm from "./FeedbackForm";
import OpenFormButton from "./OpenFormButton";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { TRootState } from "@store";
import "./styles.css";

const FeedbackFormModal = () => {
    const isLoading = useSelector((state: TRootState) => state.application.isLoading);
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <Fragment>
            {isLoading && <FullScreenLoader /> }
            {isModalVisible && <ModalWindow
                type={ModalTypes.SCREEN_LOCKER}
                onCLick={() => setIsModalVisible(false)}
            >
                <FeedbackForm closeForm={() => setIsModalVisible(false)} />
            </ModalWindow>}
            <OpenFormButton openForm={() => setIsModalVisible(true)} />
        </Fragment>
    )
}

export default FeedbackFormModal;