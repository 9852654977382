import ZipService from "@services/ZipService";
import FilesService from "@services/FilesService";
import useNotifications from "@hooks/useNotifications";
import UserFile, { type TUserFiles } from "@domains/File";
import { type IFileExplorerConfig } from "@config/FileExplorerConfig";
import { FileIOErrors, FileIOErrorsTypes } from "@utils/constants";
import { Dispatch, SetStateAction, useState } from "react";
import { setIsLoading } from "@store/slices/application";
import { AlertTypes } from "@domains/Alert";
import { useDispatch } from "react-redux";

export type TProjectFiles = Map<string, UserFile>;
export interface IFileExplorerContext {
    files: TProjectFiles;
    filesList: TUserFiles;
    currentFileId: string | null;
    hiddenFilesCount: number;
    openProjectTemplate: (
        templateName: string,
        createNotification?: boolean
    ) => Promise<void>;
    setHiddenFilesCount: Dispatch<SetStateAction<number>>;
    setCurrentFileId: Dispatch<SetStateAction<string | null>>;
    setFiles: Dispatch<SetStateAction<TProjectFiles>>;
    replaceFiles: (files: TUserFiles) => void;
    addFiles: (files: TUserFiles) => void;
    updateFile: (file: UserFile) => void;
    deleteFile: (file: UserFile) => void;
    openProject: () => Promise<void>;
    openFiles: () => Promise<void>;
    exportProject: () => void;
    deleteFiles: () => void;
}

const ENTRY_FILE_NAME = "index.js";

const useFileExplorer = (
    FileExplorerConfig: IFileExplorerConfig
): IFileExplorerContext => {
    const dispatch = useDispatch();
    const { createAlert } = useNotifications();
    const [files, setFiles] = useState<TProjectFiles>(new Map());
    const [filesList, setFilesList] = useState<TUserFiles>([]);
    const [currentFileId, setCurrentFileId] = useState<string | null>(null);
    const [hiddenFilesCount, setHiddenFilesCount] = useState<number>(0);

    const addFiles = (files: TUserFiles): void => {
        if (!files?.length) {
            throw new Error("Called add files with empty array");
        }

        setFiles((currentFiles) => {
            files.forEach((file: UserFile) => {
                const newFile: UserFile =
                    FilesService.processFileNameDuplicates(file, filesList);
                currentFiles.set(newFile.getId(), newFile);
            });

            setFilesList(Array.from(currentFiles.values()));

            return currentFiles;
        });
    };

    const updateFile = (file: UserFile): void => {
        setFiles((currentFiles) => {
            currentFiles.set(file.getId(), file);

            setFilesList(Array.from(currentFiles.values()));

            return currentFiles;
        });
    };

    const deleteFile = (file: UserFile): void => {
        setFiles((currentFiles) => {
            currentFiles.delete(file.getId());
            const newFiles: TUserFiles = Array.from(currentFiles.values());

            setFilesList(newFiles);

            const visibleFiles: TUserFiles = newFiles.filter((file: UserFile) =>
                file.getIsDisplayed()
            );

            if (!visibleFiles?.length) {
                return currentFiles;
            }

            if (currentFileId === file.getId()) {
                setCurrentFileId(visibleFiles[0].getId());
            }

            return currentFiles;
        });
    };

    const deleteFiles = (): void => {
        setFiles((currentFiles) => {
            currentFiles.clear();
            
            setFilesList([]);
            setCurrentFileId(null);
            setHiddenFilesCount(0);

            return currentFiles;
        });
    };

    const replaceFiles = (files: TUserFiles): void => {
        if (!files?.length) {
            throw new Error("Called replace files with empty array");
        }

        const newFiles: TProjectFiles = new Map();

        files.forEach((file: UserFile) => {
            newFiles.set(file.getId(), file);
        });

        setFiles(newFiles);
        setFilesList(Array.from(newFiles.values()));
    };

    const openFiles = async (): Promise<void> => {
        try {
            dispatch(setIsLoading(true));

            const uploadedFiles: TUserFiles | null =
                await FilesService.loadFilesFromUserSystem();

            if (!uploadedFiles) {
                return;
            }

            const filesToBeAdded: any = uploadedFiles.map((file: UserFile) =>
                FilesService.processFileNameDuplicates(file, filesList)
            );

            const lastAddedFileId: string =
                filesToBeAdded[filesToBeAdded.length - 1].getId();

            addFiles(filesToBeAdded);
            setCurrentFileId(lastAddedFileId);

            createAlert({
                type: AlertTypes.SUCCESS,
                title: "Successful upload", // TODO
                message: "The files were successfully uploaded to the project", // TODO
            });
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: FileIOErrorsTypes.UPLOAD,
                message: error?.message ?? error,
            });
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const openProjectTemplate = async (
        templateName: string,
        createNotification: boolean = true
    ): Promise<void> => {
        try {
            dispatch(setIsLoading(true));

            const unpackedFiles: TUserFiles | null =
                await ZipService.importZipTemplate(templateName);

            if (!unpackedFiles) {
                throw new Error("Missing files for loading a project template"); // TODO
            }

            const entryFileId = FilesService.findFileIdByFileName(
                unpackedFiles,
                ENTRY_FILE_NAME
            );

            if (!entryFileId) {
                throw new Error(FileIOErrors.NOT_INDEX_FILE_EXISTS_ERROR);
            }

            hideFilesInLoadedTemplate(unpackedFiles);
            replaceFiles(unpackedFiles);
            setCurrentFileId(entryFileId);

            if (!createNotification) {
                return;
            }

            createAlert({
                type: AlertTypes.SUCCESS,
                title: "Successful upload", // TODO
                message: `The ${templateName} has been uploaded`,
            });
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: FileIOErrorsTypes.TEMPLATE,
                message: error?.message,
            });
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const openProject = async (): Promise<void> => {
        try {
            dispatch(setIsLoading(true));

            const unpackedFiles: TUserFiles = await ZipService.uploadZip();
            const entryFileId = FilesService.findFileIdByFileName(
                unpackedFiles,
                ENTRY_FILE_NAME
            );

            if (!entryFileId) {
                throw new Error(FileIOErrors.NOT_INDEX_FILE_EXISTS_ERROR);
            }

            replaceFiles(unpackedFiles);
            setCurrentFileId(entryFileId);
            createAlert({
                type: AlertTypes.SUCCESS,
                title: "Successful upload", // TODO
                message: "The project has been uploaded", // TODO
            });
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: FileIOErrorsTypes.UPLOAD,
                message: error?.message ?? error,
            });
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const exportProject = (): void => {
        try {
            dispatch(setIsLoading(true));

            const entryFileId = FilesService.findFileIdByFileName(
                filesList,
                ENTRY_FILE_NAME
            );

            if (!entryFileId) {
                throw new Error(FileIOErrors.NOT_INDEX_FILE_EXISTS_ERROR);
            }

            const entryFile: UserFile | undefined = files.get(entryFileId);

            if (!entryFile || entryFile.getContent().length < 1) {
                throw new Error(FileIOErrors.EMPTY_FILE_ERROR);
            }

            const zip = ZipService.createZip(filesList);
            ZipService.downloadZip(zip);

            createAlert({
                type: AlertTypes.SUCCESS,
                title: "Successful export", // TODO
                message: "The project has been successfully exported", // TODO
            });
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: FileIOErrorsTypes.EXPORT,
                message: error?.message,
            });
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const hideFilesInLoadedTemplate = (files: TUserFiles): void => {
        let currentHiddenFilesCount: number = 0;
        const { hiddenFilesOnLoad } = FileExplorerConfig?.templates;

        if (!hiddenFilesOnLoad) {
            setHiddenFilesCount(currentHiddenFilesCount);
            return;
        }

        files.forEach((file: UserFile) => {
            if (!hiddenFilesOnLoad.includes(file.getFullName())) {
                return;
            }

            file.setIsDisplayed(false);
            currentHiddenFilesCount++;
        });

        setHiddenFilesCount(currentHiddenFilesCount);
    };

    return {
        files,
        filesList,
        currentFileId,
        hiddenFilesCount,
        setHiddenFilesCount,
        setCurrentFileId,
        setFiles,
        addFiles,
        openProject,
        openProjectTemplate,
        exportProject,
        openFiles,
        updateFile,
        deleteFile,
        deleteFiles,
        replaceFiles,
    };
};

export default useFileExplorer;
