import React, { useState } from "react";
import FileUploader from ".";

const FileUploaderWrapper = () => {
    // eslint-disable-next-line
    const [isValid, setIsValid] = useState(false);
    const [files, setFiles] = useState([]);

    return (
        <FileUploader
            type='file'
            uploadedFiles={files}
            handleFileUpload={(files) => setFiles(files)}
            setValidationStatus={(valid) => setIsValid(valid)}
            fileAccept={{ 'file/txt': ['.txt'] }}
        />
    );
};

const fileUploaderData = {
    component: <FileUploaderWrapper />,
    import: "import FileUploader from '@commonComponents/FileUploader';",
    usage: `<FileUploader\n\ttype="file"\n\tuploadedFiles={files}\n\thandleFileUpload={(files) => setFiles(files)}\n\tsetValidationStatus={(valid) => setIsValid(valid)}\n\tfileAccept={{"file/txt": [".txt"]}}\n/>`,
    props: {
        type: `Specifies the type of the input element, typically used to define the file input field.\n\nAllowed value:\n- "file"\n\nExample:\ntype="file"\n\n`,
        uploadedFiles: `An array of uploaded file objects.\n\nUsage:\n- Use React's useState to track the list of uploaded files.\n\nExample:\nconst [files, setFiles] = useState([]);\n\nThen set uploadedFiles to this state variable:\nuploadedFiles={files}\n\n`,
        handleFileUpload: `Callback function triggered when files are uploaded.\n\nUsage:\n- Typically, this function updates the files state using useState.\n\nExample:\nhandleFileUpload={(files) => setFiles(files)}\n\nThis updates the files array with newly uploaded files.\n\n`,
        setValidationStatus: `Callback function to update the validation status of the uploaded files.\n\nUsage:\n- Use useState to manage the validation status.\n\nExample:\nconst [isValid, setIsValid] = useState(false);\n\nsetValidationStatus={(status) => setIsValid(status)}\n\nThis updates the isValid state based on the validation outcome.\n\n`,
        fileAccept: `Specifies the types of files allowed for upload.\n\nUsage:\n- Define accepted file types as a string with extensions separated by commas.\n\nExample:\nfileAccept={{"file/txt": [".txt"]}}\n\nFor multiple types:\nfileAccept={{"image/jpeg": [".jpeg"], "image/png": [".png"]}}\n\n`,
    },
};

export default fileUploaderData;
