import MainLogo from "@components/MainLogo";
import FileIOPanel from "@components/FileIOPanel";
import snetProducts from "./SnetProductsMeta.json";
import OutlinedButton from "@components/OutlinedButton";
import AuthorizationService from "@services/AuthorizationService";
import StyledMenu from "@components/ComponentsLibrary/components/StyledMenu";
import { setIsMainMenuDisplayed } from "@store/slices/application";
import { resetSession } from "@store/slices/authorization";
import { Logout } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FAQ_LOCATION } from "@utils/constants";
import { useDispatch } from "react-redux";
import { type ReactElement } from "react";
import "./style.css";

const Header = (): ReactElement => {
    // TODO display IO only in Workspace page
    const dispatch = useDispatch();

    const openMainMenu = (): void => {
        dispatch(setIsMainMenuDisplayed(true));
    };

    const openFAQ = (): void => {
        window.open(FAQ_LOCATION, "_blank");
    };

    const signOut = async () => {
        try {
            await AuthorizationService.signOut();
            dispatch(resetSession(true));
        } catch (error) {} // TODO
    };

    return (
        <header>
            <div className="header-content">
                <div className="left-nav-side nav-side">
                    <MainLogo />
                    <FileIOPanel />
                </div>
                <div className="right-nav-side nav-side">
                    <StyledMenu
                        label={snetProducts.label}
                        list={snetProducts.list}
                    />
                    <OutlinedButton onClick={openMainMenu}>
                        {"MENU"}
                    </OutlinedButton>
                    <OutlinedButton onClick={openFAQ}>{"FAQ"}</OutlinedButton>
                    <IconButton className="sign-out-button" onClick={signOut}>
                        <Logout />
                    </IconButton>
                </div>
            </div>
        </header>
    );
};

export default Header;
