import CodeEditor from "@components/CodeEditor";
import CodeEditorContext from "@context/CodeEditorContext";
import WorkspaceToolbar from "@components/CodeEditorToolbars";
import useCodeEditorOptions from "@hooks/useCodeEditorOptions";
import FilesTabsController from "@components/FilesTabsController";
import { ReactElement } from "react";

const CodeEditorPanel = (): ReactElement => {
    return (
        <CodeEditorContext.Provider value={useCodeEditorOptions()}>
            <FilesTabsController />
            <WorkspaceToolbar />
            <CodeEditor />
        </CodeEditorContext.Provider>
    );
};

export default CodeEditorPanel;
