import { type ReactElement } from "react";
import "./style.css";

export interface IOutlinedButtonProps {
    children: string | ReactElement;
    onClick: () => void;
}

const OutlinedButton = ({
    children,
    onClick,
}: IOutlinedButtonProps): ReactElement => {
    return (
        <div className="outlined-button-container">
            <button className="outlined-button" onClick={onClick}>
                {children}
            </button>
        </div>
    );
};

export default OutlinedButton;
