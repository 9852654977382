import OutlinedButton from "@components/OutlinedButton";
import FileExplorerContext from "@context/FileExplorerContext";
import { type ReactElement, useContext, useEffect, useState } from "react";
import "./style.css";

const FileIOPanel = (): ReactElement => {
    const { openProject, exportProject } = useContext(FileExplorerContext);
    
    const [isDownloadLocked, setIsDownloadLocked] = useState<boolean>(false);

    const handleHotKeyZipDownload = (event: KeyboardEvent) => {
        if (!(event.ctrlKey && event.code === "KeyS")) {
            return;
        }

        event.preventDefault();

        if (isDownloadLocked) {
            return;
        }

        exportProject();
        setTimeout(() => setIsDownloadLocked(false), 5000);
        setIsDownloadLocked(true);
    };

    useEffect(() => {
        document.addEventListener("keydown", handleHotKeyZipDownload);

        return () => {
            document.removeEventListener("keydown", handleHotKeyZipDownload);
        };
    });

    return (
        <div className="zip-manager">
            <OutlinedButton onClick={openProject}>
                {"OPEN PROJECT"}
            </OutlinedButton>
            <OutlinedButton onClick={exportProject}>
                {"EXPORT PROJECT"}
            </OutlinedButton>
        </div>
    );
};

export default FileIOPanel;
