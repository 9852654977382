export interface ITemplatesConfig {
    hiddenFilesOnLoad: string[];
}

export interface IFileExplorerConfig {
    templates: ITemplatesConfig;
}

const FileExplorerConfig: IFileExplorerConfig = {
    templates: {
        hiddenFilesOnLoad: ["style.css"],
    },
};

export default FileExplorerConfig;
