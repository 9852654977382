import NoResultsPanel from "@panels/NoResultsPanel";
import ProjectTemplatesList from "@components/ProjectTemplatesList";
import ProjectTemplatesHeader from "@components/ProjectTemplatesHeader";
import { IProjectTemplateData, templatesList } from "./meta";
import { ReactElement, useMemo, useState } from "react";
import "./style.css";

const searchFunction = (
    template: IProjectTemplateData,
    searchQuery: string
): boolean => {
    return (
        template.description.toLowerCase().includes(searchQuery) ||
        template.title.toLowerCase().includes(searchQuery)
    );
};

const filterByTypeFunction = (
    template: IProjectTemplateData,
    typeFilters: string[]
): boolean => {
    return (
        typeFilters.includes(template.sourceTemplateType) ||
        typeFilters.includes(template.targetTemplateType)
    );
};

const ProjectTemplatesPanel = (): ReactElement => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchFilters, setSearchFilters] = useState<string[]>([]);

    const filterTemplates = (): IProjectTemplateData[] => {
        const searchedTemplates: IProjectTemplateData[] = templatesList.filter(
            (template) => searchFunction(template, searchQuery)
        );

        if (!searchFilters?.length) {
            return searchedTemplates;
        }

        const filteredTemplates: IProjectTemplateData[] =
            searchedTemplates.filter((template) =>
                filterByTypeFunction(template, searchFilters)
            );

        return filteredTemplates;
    };

    const templates: IProjectTemplateData[] = useMemo(filterTemplates, [
        searchQuery,
        searchFilters,
    ]);

    return (
        <div className="project-templates-panel">
            <ProjectTemplatesHeader
                onQueryInput={setSearchQuery}
                onFiltersChange={setSearchFilters}
            />
            {!templates?.length ? (
                <NoResultsPanel />
            ) : (
                <ProjectTemplatesList templates={templates} />
            )}
        </div>
    );
};

export default ProjectTemplatesPanel;
