import { DevToolsTabsKeys } from "@panels/DeveloperToolsPanel/meta";
import { Dispatch, SetStateAction, useState } from "react";

export interface IWorkspaceContext {
    developerToolsTab: string;
    setDeveloperToolsTab: Dispatch<SetStateAction<string>>;
}

const useWorkspace = (): IWorkspaceContext => {
    const [developerToolsTab, setDeveloperToolsTab] = useState<string>(
        DevToolsTabsKeys.PREVIEW_AREA
    );

    return {
        developerToolsTab,
        setDeveloperToolsTab,
    };
};

export default useWorkspace;
