import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import LocalStorageService from "@services/localStorageService";
import { LocalStorageEditorSettingsKeys } from "@utils/constants";
import {
    defaultEditorSettingsValues,
    EditorSettingsKeys,
} from "@utils/configs/editorConfigData";

export interface ICodeEditorContext {
    isEditorLoading: boolean;
    editorLineHeight: number;
    editorFontSize: number;
    setIsEditorLoading: Dispatch<SetStateAction<boolean>>;
    changeLineHeight: (value: number) => void;
    changeFontSize: (value: number) => void;
}

const useCodeEditorOptions = () => {
    const [isEditorLoading, setIsEditorLoading] = useState<boolean>(false);

    const [editorLineHeight, setEditorLineHeight] = useState<number>(
        LocalStorageService.getValue(
            LocalStorageEditorSettingsKeys.LINE_HEIGHT,
            defaultEditorSettingsValues[EditorSettingsKeys.LINE_HEIGHT]
        ) as number
    );

    const [editorFontSize, setEditorFontSize] = useState<number>(
        LocalStorageService.getValue(
            LocalStorageEditorSettingsKeys.FONT_SIZE,
            defaultEditorSettingsValues[EditorSettingsKeys.FONT_SIZE]
        ) as number
    );

    const changeLineHeight = (newValue: number): void => {
        setEditorLineHeight(newValue);
        LocalStorageService.setValue(LocalStorageEditorSettingsKeys.LINE_HEIGHT, newValue);
    };

    const changeFontSize = (newValue: number): void => {
        setEditorFontSize(newValue);
        LocalStorageService.setValue(LocalStorageEditorSettingsKeys.FONT_SIZE, newValue);
    };

    return {
        isEditorLoading,
        editorFontSize,
        editorLineHeight,
        changeFontSize,
        setIsEditorLoading,
        changeLineHeight,
    };
};

export default useCodeEditorOptions;
