import UserFile from "@domains/File";
import CustomLoader from "@components/CustomLoader";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import CodeEditorContext from "@context/CodeEditorContext";
import FileExplorerContext from "@context/FileExplorerContext";
import Editor, { OnMount, OnChange, Monaco } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import {
    LINE_HEIGHT_PERCENTAGE_MULTIPLIER,
    MonacoScrollBarSettings,
} from "@utils/constants";
import { useContext, useEffect, useRef, type ReactElement } from "react";
import "./style.css";

const EditorLanguagesByFileExtension: Record<string, string> = {
    css: "css",
    js: "javascript",
    default: "javascript",
};

const CodeEditor = (): ReactElement => {
    // const appTheme: Themes = useSelector(
    //     (state: TRootState) => state.application.currentTheme
    // );

    const editorRef = useRef<editor.ICodeEditor | null>(null);

    const {
        editorLineHeight,
        editorFontSize,
        isEditorLoading,
        setIsEditorLoading,
    } = useContext(CodeEditorContext);

    const lineHeight: number =
        editorLineHeight / LINE_HEIGHT_PERCENTAGE_MULTIPLIER;

    const { files, currentFileId, updateFile } =
        useContext(FileExplorerContext);

    const currentFile: UserFile | undefined = !currentFileId
        ? undefined
        : files.get(currentFileId);

    const currentLanguage: string =
        EditorLanguagesByFileExtension[
            currentFile?.getExtension() ?? "default"
        ];

    const onFileContentValueChange: OnChange = (value: string | undefined) => {
        if (!value || !currentFile) {
            return;
        }

        currentFile.setContent(value);
        updateFile(currentFile);
    };

    const onEditorMount: OnMount = (
        editor: editor.IStandaloneCodeEditor,
        monaco: Monaco
    ) => {
        setIsEditorLoading(true);
        editorRef.current = editor;
        setIsEditorLoading(false);
    };

    useEffect(() => {
        editorRef.current?.focus();
        // handle cached content
        editorRef.current?.setValue(currentFile?.getContent() || "");
    }, [currentFile]);

    if (isEditorLoading) {
        return <CustomLoader title="Loading..." />;
    }

    if (!currentFile) {
        return (
            <div className="editor-stub-container">
                <SvgSpriteIcon
                    className={"editor-stub-icon"}
                    id={"singularity-stub-icon"}
                />
            </div>
        );
    }

    return (
        <div className="editor-container">
            <Editor
                height={"100%"}
                width={"100%"}
                language={currentLanguage}
                path={currentFile.getFullName()}
                value={currentFile.getContent()}
                onChange={onFileContentValueChange}
                onMount={onEditorMount}
                options={{
                    lineHeight: lineHeight,
                    fontSize: editorFontSize,
                    scrollbar: MonacoScrollBarSettings,
                }}
            />
        </div>
    );
};

export default CodeEditor;
