import React from "react";
import StyledButton from ".";

const styledButtonData = {
    component: (
        <StyledButton
            disabled={false}
            onClick={() => alert("Button is clicked")}
            newTab={false}
            btnText={"Button"}
        />
    ),
    import: "import StyledButton from '@integratedComponents/StyledButton';",
    usage: `<StyledButton\n\tdisabled={false}\n\tonClick={() => alert("Button is clicked")}\n\tbtnText={"Button"}\n/>`,
    props: {
        disabled: `Specifies whether the button is active or disabled.\n\nAllowed values:\n\t- true: disables the button\n\t- false: enables the button\n\nExample:\n\tdisabled={false}\n\n`,
        onClick: `Callback function that triggers when the button is clicked.\n\nYou can specify any function here to execute on click.\n\nExample:\n\tonClick={() => alert("Button is clicked")}\n\n`,
        btnText: `The text displayed on the button.\n\nExample:\n\tbtnText={"Button"}\n\n`,
    },
};

export default styledButtonData;
