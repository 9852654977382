import IAlertOptions from "@domains/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { default as MUIAlert } from "@mui/material/Alert";
import { type ReactElement } from "react";
import "./style.css";

export interface IAlertProps {
    alertOptions: IAlertOptions;
}

const Alert = ({ alertOptions }: IAlertProps): ReactElement => {
    return (
        <div className="system-alert">
            <MUIAlert severity={alertOptions.type} color={alertOptions.type}>
                <AlertTitle>{alertOptions.title}</AlertTitle>
                {alertOptions.message}
            </MUIAlert>
        </div>
    );
};

export default Alert;
