import { useState, type MouseEvent } from "react";
import "./style.css";

export interface ILabeledCheckboxProps {
    label: string;
    initialValue?: boolean;
    onChange: (isActive: boolean) => void;
}

const LabeledCheckbox = ({
    label,
    initialValue = false,
    onChange,
}: ILabeledCheckboxProps) => {
    const [isActive, setIsActive] = useState<boolean>(initialValue);

    const className: string = `labeled-checkbox ${!isActive ? "": "active"}`;

    const handleClick = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setIsActive(!isActive);
        onChange(!isActive);
    };

    return (
        <div className={className} onClick={handleClick}>
            <div className="checkbox-item">
                {isActive && (
                    <div className="active-indicator">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="11"
                            viewBox="0 0 14 11"
                            fill="none"
                        >
                            <path
                                d="M1 5L5 9L13 1"
                                stroke="white"
                                strokeWidth="2"
                            />
                        </svg>
                    </div>
                )}
            </div>
            <div className="checkbox-label">{label}</div>
        </div>
    );
};

export default LabeledCheckbox;
