export enum StartTemplates {
    EXAMPLE_SERVICE = "example_service.zip",
    DRAFT = "service_draft.zip",
};

export enum TemplatesTypes {
    TEXT = "text",
    IMAGE = "image",
    AUDIO = "audio",
    FILE = "file",
}

export const templatesTypesList = Object.values(TemplatesTypes);

export interface IProjectTemplateData {
    key: string;
    title: string;
    fileName: string;
    description: string;
    sourceTemplateTypeIconName: string;
    targetTemplateTypeIconName: string;
    sourceTemplateType: string;
    targetTemplateType: string;
}

export const templatesList: IProjectTemplateData[] = [
    {
        key: "t2t",
        title: "Text to Text",
        fileName: "text_to_text_template.zip",
        description: "Input: text, output: text. This template defines that the service expects text data as input and returns text as output, for example, for text processing or analysis.",
        sourceTemplateTypeIconName: "text-source",
        targetTemplateTypeIconName: "text-source",
        sourceTemplateType: TemplatesTypes.TEXT,
        targetTemplateType: TemplatesTypes.TEXT,
    },
    {
        key: "t2i",
        title: "Text to Image",
        fileName: "text_to_image_template.zip",
        description: "Input: text, output: image in base64 format. This template specifies that the service accepts text data as input, and returns an image as output. Data is transmitted in base64 format.",
        sourceTemplateTypeIconName: "text-source",
        targetTemplateTypeIconName: "image-target",
        sourceTemplateType: TemplatesTypes.TEXT,
        targetTemplateType: TemplatesTypes.IMAGE,
    },
    {
        key: "i2t",
        title: "Image to Text ",
        fileName: "image_to_text_template.zip",
        description: "Input: image binary data, output: text. This template specifies that the service accepts an image in binary format as input, and returns text information (such as a description or metadata of the image) as output.",
        sourceTemplateTypeIconName: "image-source",
        targetTemplateTypeIconName: "text-source",
        sourceTemplateType: TemplatesTypes.IMAGE,
        targetTemplateType: TemplatesTypes.TEXT,
    },
    {
        key: "i2i",
        title: "Image to Image",
        fileName: "image_to_image_template.zip",
        description: "Input: image binary data, output: image in base64 format. This template is used for services that accept an image in binary format as input and return an image as output. Data is transmitted in base64 format.",
        sourceTemplateTypeIconName: "image-source",
        targetTemplateTypeIconName: "image-target",
        sourceTemplateType: TemplatesTypes.IMAGE,
        targetTemplateType: TemplatesTypes.IMAGE,
    },
    {
        key: "t2a",
        title: "Text to Audio",
        fileName: "text_to_audio_template.zip",
        description: "Input: text, output: audio file in base64 format. This template defines that the service expects text as input and returns audio as output, for example, for text-to-speech synthesis. Data is transmitted in base64 format.",
        sourceTemplateTypeIconName: "text-source",
        targetTemplateTypeIconName: "audio-target",
        sourceTemplateType: TemplatesTypes.TEXT,
        targetTemplateType: TemplatesTypes.AUDIO,
    },
    {
        key: "a2t",
        title: "Audio to Text",
        fileName: "audio_to_text_template.zip",
        description: "Input: audio file binary data, output: text. This template specifies that the service accepts an audio file in binary format as input and returns text, such as a speech transcription, as output.",
        sourceTemplateTypeIconName: "audio-source",
        targetTemplateTypeIconName: "text-source",
        sourceTemplateType: TemplatesTypes.AUDIO,
        targetTemplateType: TemplatesTypes.TEXT,
    },
    {
        key: "a2a",
        title: "Audio to Audio",
        fileName: "audio_to_audio_template.zip",
        description: "Input: audio file binary data, output: audio file. This template defines that the service accepts an audio file in binary format as input and returns audio as output. Data is transmitted in base64 format.",
        sourceTemplateTypeIconName: "audio-source",
        targetTemplateTypeIconName: "audio-target",
        sourceTemplateType: TemplatesTypes.AUDIO,
        targetTemplateType: TemplatesTypes.AUDIO,
    },
    {
        key: "t2F",
        title: "Text to File",
        fileName: "text_to_file_template.zip",
        description: "Input: text, output: file. This template defines that the service receives text as input and generates a file in binary format as output. Data is transmitted in base64 format.",
        sourceTemplateTypeIconName: "text-source",
        targetTemplateTypeIconName: "file-target",
        sourceTemplateType: TemplatesTypes.TEXT,
        targetTemplateType: TemplatesTypes.FILE,
    },
    {
        key: "f2t",
        title: "File to Text",
        fileName: "file_to_text_template.zip",
        description: "Input: file binary data, output: text. This template is used for services that accept a file in binary format as input and return text information derived from that file.",
        sourceTemplateTypeIconName: "file-source",
        targetTemplateTypeIconName: "text-source",
        sourceTemplateType: TemplatesTypes.FILE,
        targetTemplateType: TemplatesTypes.TEXT,
    },
    {
        key: "f2f",
        title: "File to File",
        fileName: "file_to_file_template.zip",
        description: "Input: file, output: file. This template defines that the service accepts a binary file as input and returns a file as output. Data is transmitted in base64 format.",
        sourceTemplateTypeIconName: "file-source",
        targetTemplateTypeIconName: "file-target",
        sourceTemplateType: TemplatesTypes.FILE,
        targetTemplateType: TemplatesTypes.FILE,
    },
];
