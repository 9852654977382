import CustomLoader from "@components/CustomLoader";
import ModalWindow, { ModalTypes } from "@components/ModalWindow";
import { type ReactElement } from "react";

const FullScreenLoader = (): ReactElement => {
    return (
        <ModalWindow type={ModalTypes.SCREEN_LOCKER} zIndex={500}>
            <CustomLoader fillColor="#D6D6D6" />
        </ModalWindow>
    );
};

export default FullScreenLoader;
