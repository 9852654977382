import UserFile from "@domains/File";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import FileExplorerContext from "@context/FileExplorerContext";
import DisplayedFilesSelector from "@components/DisplayedFilesSelector";
import { useContext, type ReactElement } from "react";
import "./style.css";

export interface IToolBarButton {
    key: string;
    title: string;
    icon: string;
    action: () => void;
}

const FileExplorerToolbar = (): ReactElement => {
    const { addFiles, setCurrentFileId, openFiles } =
        useContext(FileExplorerContext);

    const createNewFile = (): void => {
        const file = new UserFile();

        addFiles([file]);
        setCurrentFileId(file.getId());
    };

    const toolbarButtons: IToolBarButton[] = [
        {
            key: "add",
            icon: "add-icon",
            title: "Create file",
            action: createNewFile,
        },
        {
            key: "upload",
            icon: "upload-icon",
            title: "Upload file",
            action: openFiles,
        },
    ];

    return (
        <div className="file-explorer-toolbar">
            {toolbarButtons.map((toolbarButton) => (
                <button
                    className="file-explorer-toolbar-button"
                    key={toolbarButton.key}
                    title={toolbarButton.title}
                    onClick={toolbarButton.action}
                >
                    <div className="upload-icon-container">
                        <SvgSpriteIcon
                            className={toolbarButton.icon}
                            id={toolbarButton.icon}
                        />
                    </div>
                </button>
            ))}
            <DisplayedFilesSelector />
        </div>
    );
};

export default FileExplorerToolbar;
