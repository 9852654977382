import Tab from "@components/Tab";
import { memo, ReactElement, ReactNode, useMemo } from "react";
import "./style.css";

export interface ITbasProps {
    children: ReactElement | ReactElement[];
    activeTabKey: string | null;
    onTabChange?: (tabId: string) => void;
}

const Tabs = memo(
    ({ activeTabKey, children, onTabChange }: ITbasProps): ReactNode => {
        const tabsList = useMemo(() => {
            return !Array.isArray(children) ? [children] : children;
        }, [children]);

        if (!tabsList?.length) {
            return null;
        }

        const handleTabChange = (newActiveTabKey: string | null): void => {
            if (!onTabChange || !newActiveTabKey) {
                return;
            }

            onTabChange(newActiveTabKey);
        };

        return (
            <div className="tabs">
                {tabsList.map((TabContent) => (
                    <Tab
                        key={TabContent.key}
                        onCLick={() => handleTabChange(TabContent.key)}
                        isActive={TabContent.key === activeTabKey}
                    >
                        {TabContent}
                    </Tab>
                ))}
            </div>
        );
    }
);

export default Tabs;
