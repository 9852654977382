import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    resetSession,
    setAuthorizationToken,
    setIsUserAuthorized,
} from "@store/slices/authorization";
import ModalWindow, { ModalTypes } from "@components/ModalWindow";
import AuthorizationService from "@services/AuthorizationService";
import { TRootState } from "@store/index";
import Signup from "./Signup";
import "./styles.css";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import useNotifications from "@hooks/useNotifications";
import { AlertTypes } from "@domains/Alert";

export enum EAuthFormType {
    login = "login",
    signUp = "signUp",
    forgotPassword = "forgotPassword",
}

const Authorization = () => {
    const dispatch = useDispatch();
    const { createAlert } = useNotifications();
    const [isSessionFetching, setIsSessionFetching] = useState<boolean>(false);
    const [authFormType, setAuthFormType] = useState<
        keyof typeof EAuthFormType
    >(EAuthFormType.login);
    const isUserAuthorized = useSelector(
        (state: TRootState) => state.authorization.isUserAuthorized
    );

    const setSignUpForm = () => setAuthFormType(EAuthFormType.signUp);
    const setSignInForm = () => setAuthFormType(EAuthFormType.login);
    const setForgotPasswordForm = () =>
        setAuthFormType(EAuthFormType.forgotPassword);

    const getSession = async () => {
        if (isUserAuthorized) {
            return;
        }

        try {
            setIsSessionFetching(true);
            const idToken =
                await AuthorizationService.getCurrentAuthSession();
            dispatch(setAuthorizationToken(idToken));
            dispatch(setIsUserAuthorized(true));
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Unauthorized",
                message: error?.message,
            })
            console.error("get session error: ", error);
            dispatch(resetSession(true));
        } finally {
            setIsSessionFetching(false);
        }
    };

    useEffect(() => {
        getSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const authorizationFormByType = {
        login: (
            <Login
                setSignUpForm={setSignUpForm}
                setForgotPasswordForm={setForgotPasswordForm}
            />
        ),
        signUp: <Signup setSignInForm={setSignInForm} />,
        forgotPassword: <ForgotPassword setSignInForm={setSignInForm} />,
    };

    if (isSessionFetching) {
        return null;
    }

    return (
        <Fragment>
            {!isUserAuthorized && (
                <ModalWindow type={ModalTypes.SCREEN_LOCKER}>
                    <div className="authorization-form">
                        {authorizationFormByType[authFormType]}
                    </div>
                </ModalWindow>
            )}
        </Fragment>
    );
};

export default Authorization;
