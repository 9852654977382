import routerProps from "@router";
import DialogHolder from "@components/DialogHolder";
import AlertsHolder from "@components/AlertsHolder";
import Authorization from "@components/Authorization";
import FullScreenLoader from "@components/FullScreenLoader";
import FeedbackFormModal from "@components/FeedbackFormModal";
import useWindowResizeObserver from "@hooks/useWindowResizeObserver";
import { type ReactElement } from "react";
import { type TRootState } from "@store";
import { RouterProvider } from "react-router-dom";
import { useSelector } from "react-redux";
import { Amplify } from "aws-amplify";
import { getAwsConfig } from "@api/awsApi";
import "@domains/Theme/style.css";
import "./style.css";

const Application = (): ReactElement => {
    useWindowResizeObserver();

    const isTabletMode: boolean = useSelector(
        (state: TRootState) => state.application.isTabletMode
    );
    const appTheme = useSelector(
        (state: TRootState) => state.application.currentTheme
    );
    const isLoading = useSelector(
        (state: TRootState) => state.application.isLoading
    );

    Amplify.configure(getAwsConfig());

    const className: string = `app ${appTheme} ${
        !isTabletMode ? "" : "tablet-mode"
    }`;

    return (
        <div className={className}>
            {isLoading && <FullScreenLoader />}
            <RouterProvider {...routerProps} />
            <FeedbackFormModal />
            <Authorization />
            <DialogHolder />
            <AlertsHolder />
        </div>
    );
};

export default Application;
