import { ReactElement } from "react";
import "./style.css";

export interface ISettingsButton {
    isTooltipSizeExpanded?: boolean;
    tooltipText?: string;
    children: ReactElement;
}

const TooltipWrapper = ({
    isTooltipSizeExpanded,
    tooltipText = "",
    children,
}: ISettingsButton): ReactElement => {
    const wrapperClassName: string = !isTooltipSizeExpanded
        ? "tooltip-wrapper"
        : "tooltip-wrapper expanded";

    return (
        <div className={wrapperClassName}>
            <div className="tooltip-text">{tooltipText}</div>
            {children}
        </div>
    );
};

export default TooltipWrapper;
