import IDialogData from "@domains/Dialog";
import Button from "@mui/material/Button";
import CloseButton from "@components/CloseButton";
import { type ReactElement } from "react";
import "./style.css";

export interface IAlertDialogProps {
    dialogData: IDialogData;
    isCloseButtonShown?: boolean;
}

const Dialog = ({
    dialogData,
    isCloseButtonShown = false,
}: IAlertDialogProps): ReactElement => {
    return (
        <div className="dialog">
            <div className="dialog-head">
                {dialogData?.title && (
                    <h3 className="dialog-title">{dialogData.title}</h3>
                )}
                {isCloseButtonShown && (
                    <CloseButton onClick={dialogData.cancelAction} />
                )}
            </div>
            <div className="dialog-body">
                <div className="dialog-content">{dialogData.message}</div>
                <div className="dialog-buttons">
                    <Button
                        autoFocus={true}
                        variant="contained"
                        size="medium"
                        onClick={dialogData.submitAction}
                    >
                        {dialogData.submitLabel}
                    </Button>
                    {dialogData?.cancelLabel && (
                        <Button
                            variant="contained"
                            size="medium"
                            color="error"
                            onClick={dialogData.cancelAction}
                        >
                            {dialogData.cancelLabel}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Dialog;
