import { setIsTabletMode } from "@store/slices/application";
import { TABLET_MODE_BREAKPOINT } from "@utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "@store/index";

const useWindowResizeObserver = () => {
    const dispatch = useDispatch();

    const isTabletModeCurrent: boolean = useSelector(
        (state: TRootState) => state.application.isTabletMode
    );

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState<number>(
        window.innerHeight
    );

    const update = useCallback((): void => {
        const isTabletMode: boolean =
            window.innerWidth < TABLET_MODE_BREAKPOINT;

        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);

        if (isTabletMode === isTabletModeCurrent) {
            return;
        }

        dispatch(setIsTabletMode(isTabletMode));
    }, [dispatch, isTabletModeCurrent]);

    useEffect(() => {
        update();

        window.addEventListener("resize", update);

        return () => {
            window.removeEventListener("resize", update);
        };
    }, [update]);

    return { windowWidth, windowHeight };
};

export default useWindowResizeObserver;
