import { TUserSignUpCredentials, TUserSignInCredentials, TUserEmail, TUserConfirmationCridentials, TUserResetCridentials } from "@domains/User";
import {
    fetchAuthSession,
    signUp as signUpAws,
    signIn as signInAws,
    signOut as signOutAws,
    resetPassword as resetPasswordAws,
    confirmResetPassword as confirmResetPasswordAws,
    resendSignUpCode,
    confirmSignUp as confirmSignUpAws,
} from "aws-amplify/auth";

export default class AuthorizationService {
    public static async getCurrentAuthSession() {
        const response = await fetchAuthSession();
        const { tokens } = response;
        const idToken = tokens?.idToken?.toString();
        if (!idToken) {
          throw new Error("No active sessions, please login");
        }
        return idToken
      };

    public static async signUp({nickname, email, password}: TUserSignUpCredentials) {
        await signUpAws({
          username: email,
          password,
          options: {
            userAttributes: {
              email,
              nickname,
            },
          },
        })
    };

    public static async signIn({email, password}: TUserSignInCredentials) {
      await signInAws({ username: email, password });
      await this.getCurrentAuthSession();
    }

    public static async signOut() {
      await signOutAws();
    }

    public static async resendCode({ email }: TUserEmail) {
      await resendSignUpCode({ username: email })
    };

    public static async confirmSignUp({email, verificationCode}: TUserConfirmationCridentials) {
      await confirmSignUpAws({ username: email, confirmationCode: verificationCode });
    }

    public static async resetPassword({ email }: TUserEmail) {
      await resetPasswordAws({ username: email })
    }

    public static async confirmResetPassword({email, password, verificationCode}: TUserResetCridentials) {
      await confirmResetPasswordAws({ username: email, newPassword: password, confirmationCode: verificationCode })
    }
}