import SvgSpriteIcon from "@components/SvgSpriteIcon";
import {
    type ReactElement,
    type Dispatch,
} from "react";
import { MIN_PANEL_WIDTH } from "@utils/constants";
import "./style.css";

export type TPanelWidth = {
    LEFT: string;
    RIGHT: string;
};

export interface IBarProps {
    setPanelWidth: Dispatch<TPanelWidth>;
}

const PERCENT_MULTIPLIER: number = 100;
const SCREEN_WIDTH: number = 100; // %
const MAX_PANEL_WIDTH: number = 100 - MIN_PANEL_WIDTH;

const SplitBar = ({ setPanelWidth }: IBarProps): ReactElement => {

    const transformOnMove = (event: MouseEvent | Touch): void => {       
        const newPanelWidthValue: number =
            (event.clientX / window.innerWidth) * PERCENT_MULTIPLIER;

        if (
            newPanelWidthValue < MIN_PANEL_WIDTH ||
            newPanelWidthValue > MAX_PANEL_WIDTH
        ) {
            return;
        }

        setPanelWidth({
            LEFT: `${newPanelWidthValue}%`,
            RIGHT: `${SCREEN_WIDTH - newPanelWidthValue}%`,
        });
    };

    const transformOnTouchMode = (event: TouchEvent): void => {
        event.preventDefault();

        const currentTouch: Touch | null = event.changedTouches.item(0);

        if (!currentTouch) {
            return;
        }

        transformOnMove(currentTouch);
    }

    const handleDragStart = (event: React.MouseEvent<HTMLElement>): void => {
        document.addEventListener("mouseup", handleDragEnd);
        document.addEventListener("mousemove", transformOnMove);
    };

    const handleDragEnd = (): void => {
        document.removeEventListener("mouseup", handleDragEnd);
        document.removeEventListener("mousemove", transformOnMove);
    };

    const handleTouchDragStart = (): void => {
        document.addEventListener("touchend", handleTouchDragEnd);
        document.addEventListener("touchmove", transformOnTouchMode);
    };

    const handleTouchDragEnd = (): void => {
        document.removeEventListener("touchend", handleTouchDragEnd);
        document.removeEventListener("touchmove", transformOnTouchMode);
    }

    return (
        <div
            className="split-bar"
            onMouseDown={handleDragStart}
            onTouchStart={handleTouchDragStart}
        >
            <SvgSpriteIcon className={"split-line"} id={"split-line"} />
        </div>
    );
};

export default SplitBar;
