import UserFile from "@domains/File";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import LabeledCheckbox from "@components/LabeledCheckbox";
import FileExplorerContext from "@context/FileExplorerContext";
import {
    type ReactNode,
    type MouseEvent,
    useContext,
    useEffect,
    useState,
    useMemo,
} from "react";
import "./style.css";

export interface IUserFileIdentificationData {
    id: string;
    name: string;
    isShown: boolean;
}

const DisplayedFilesSelector = (): ReactNode => {
    const {
        filesList,
        currentFileId,
        hiddenFilesCount,
        setHiddenFilesCount,
        updateFile,
        setCurrentFileId,
    } = useContext(FileExplorerContext);

    const [isOpen, setIsOpen] = useState<boolean>();

    useEffect(() => {
        document.addEventListener("click", () => setIsOpen(false));

        return document.removeEventListener("click", () => setIsOpen(false));
    }, []);

    const toggleButtonIconName: string = useMemo(() => {
        const activityString: string = !isOpen ? "" : "-active";

        if (!filesList?.length) {
            return "";
        }

        if (!hiddenFilesCount) {
            return "open-eye" + activityString;
        }

        if (hiddenFilesCount === filesList.length) {
            return "closed-eye" + activityString;
        }

        if (hiddenFilesCount >= 1) {
            return "half-eye" + activityString
        }

        return "open-eye";
    }, [hiddenFilesCount, filesList, isOpen]);

    if (!filesList?.length) {
        return null;
    }

    const toggleButtonClassName: string = `files-selector-toggle-button ${
        !isOpen ? "" : "active"
    }`;

    const listContainerClassName: string = `files-selector-container ${
        !isOpen ? "" : "open"
    }`;

    const showFile = (file: UserFile): void => {
        if (hiddenFilesCount === filesList.length) {
            setCurrentFileId(file.getId());
        }

        setHiddenFilesCount(hiddenFilesCount - 1);
    };

    const hideFile = (file: UserFile): void => {
        if (hiddenFilesCount === filesList.length - 1) {
            setCurrentFileId(null);
            setHiddenFilesCount(hiddenFilesCount + 1);
            return;
        }

        if (file.getId() !== currentFileId) {
            setHiddenFilesCount(hiddenFilesCount + 1);
            return;
        }

        const nextActiveFile: UserFile | undefined = filesList.find(
            (file: UserFile) => file.getIsDisplayed()
        );

        setHiddenFilesCount(hiddenFilesCount + 1);
        setCurrentFileId(nextActiveFile?.getId() ?? null);
    };

    const setFileVisibility = (file: UserFile, isDisplayed: boolean): void => {
        file.setIsDisplayed(isDisplayed);

        !isDisplayed ? hideFile(file) : showFile(file);

        updateFile(file);
    };

    const stopEventPropagation = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <div
            onClick={stopEventPropagation}
            title="Show or hide files"
            className="displayed-files-selector-container"
        >
            <div
                className={toggleButtonClassName}
                onClick={() => setIsOpen(!isOpen)}
            >
                <SvgSpriteIcon
                    id={toggleButtonIconName}
                    className="files-selector-toggle-button-icon"
                />
            </div>
            <div className={listContainerClassName}>
                <div className="files-selector-title">
                    <h3>Shown files</h3>
                </div>
                <div className="files-selector-content">
                    {filesList.map((file: UserFile) => (
                        <div
                            key={file.getId()}
                            title={file.getFullName()}
                            className="file-visibility-selector"
                        >
                            <LabeledCheckbox
                                label={file.getFullName()}
                                initialValue={file.getIsDisplayed()}
                                onChange={(isDisplayed) =>
                                    setFileVisibility(file, isDisplayed)
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DisplayedFilesSelector;
