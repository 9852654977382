import SvgSpriteIcon from "@components/SvgSpriteIcon";
import {
    SwitchDirections,
    switchScreenSides,
} from "@components/WorkspacePanels";
import { ReactElement } from "react";
import "./style.css";

export interface ITabletModeScreenSwitchProps {
    isArrowInverted?: boolean;
}

const TabletModeScreenSwitch = ({
    isArrowInverted = false,
}: ITabletModeScreenSwitchProps): ReactElement => {
    const className: string = `screen-switch-button ${
        !isArrowInverted ? "" : "inverted"
    }`;

    const switchDirection: SwitchDirections = !isArrowInverted
        ? SwitchDirections.RIGHT
        : SwitchDirections.LEFT;

    return (
        <div
            className="screen-switch-container"
            onClick={() => switchScreenSides(switchDirection)}
        >
            <div className={className}>
                <SvgSpriteIcon id="tablet-arrow" className="tablet-arrow" />
            </div>
        </div>
    );
};

export default TabletModeScreenSwitch;
