import SearchBar from "@components/SearchBar";
import FiltersList from "@components/FiltersList";
import { templatesTypesList } from "@panels/ProjectTemplatesPanel/meta";
import { ReactElement } from "react";
import "./style.css";
export interface IProjectTemplatesHeaderProps {
    onQueryInput: (value: string) => void;
    onFiltersChange: (filters: string[]) => void;
}

const ProjectTemplatesHeader = ({
    onQueryInput,
    onFiltersChange,
}: IProjectTemplatesHeaderProps): ReactElement => {
    return (
        <div className="project-templates-header">
            <SearchBar onInput={onQueryInput} />
            <FiltersList
                filters={templatesTypesList}
                onActiveFiltersListChange={onFiltersChange}
            />
        </div>
    );
};

export default ProjectTemplatesHeader;
