import {
    useState,
    ChangeEvent,
    SetStateAction,
    useEffect,
    useCallback,
} from "react";
import { useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AlertText from "@components/ComponentsLibrary/components/AlertText";
import { alertTypes } from "@components/ComponentsLibrary/components/AlertBox";

import {
    signupFormConstraints,
    passwordInlineConstraints,
    TPasswordContraintNames,
} from "./validationConstraints";
import snetValidator from "@utils/functions/snetValidator";
import { isEmpty } from "validate.js";
import { setIsLoading } from "@store/slices/application";
import AuthorizationService from "@services/AuthorizationService";
import { setUser } from "@store/slices/authorization";
import "./styles.css";
import { InputAdornment } from "@mui/material";
import { eventListenerForKey } from "@utils/functions";
import useNotifications from "@hooks/useNotifications";
import { AlertTypes } from "@domains/Alert";

interface iPasswordCriteria {
    constraint: TPasswordContraintNames;
    message: string;
}

const passwordCriteriaMeta: iPasswordCriteria[] = [
    { constraint: "upperCase", message: "Uppercase" },
    { constraint: "lowerCase", message: "Lowercase" },
    { constraint: "length", message: "Min 8 Chars" },
    { constraint: "AWSSplChars", message: "A Special Char" },
    { constraint: "number", message: "Number" },
];

export interface IRegistrationForm {
    setIsCodeWaiting: (isCodeWaiting: boolean) => void;
    setSignInForm: () => void;
}

const RegistrationForm = ({
    setIsCodeWaiting,
    setSignInForm,
}: IRegistrationForm) => {
    const dispatch = useDispatch();
    const { createAlert } = useNotifications();

    const [nickname, setNickname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [validationErrors, setValidationErrors] = useState<
        string[] | undefined
    >();
    const isFilledAllFields: boolean = !!nickname && !!email && !!password;

    const registrationFieldMeta = [
        {
            id: "nickname",
            label: "Nickname",
            value: nickname,
            setter: setNickname,
        },
        { id: "email", label: "Email", value: email, setter: setEmail },
        {
            id: "password",
            label: "Password",
            value: password,
            setter: setPassword,
        },
    ];

    const isFormValid = useCallback((): boolean => {
        const isNotValid = snetValidator(
            { nickname, email, password },
            signupFormConstraints
        );

        if (isNotValid) {
            setValidationErrors(isNotValid);
            return false;
        }
        setValidationErrors(undefined);
        return true;
    }, [nickname, email, password]);

    useEffect(() => {
        if (!isFilledAllFields) {
            return;
        }
        isFormValid();
    }, [isFilledAllFields, nickname, password, email, isFormValid]);

    const onInputFormField = (
        event: ChangeEvent<HTMLInputElement>,
        setter: { (value: SetStateAction<string>): void }
    ) => {
        setter(event.target.value);
    };

    const handleSubmit = async () => {
        dispatch(setIsLoading(true));
        try {
            await AuthorizationService.signUp({ nickname, email, password });
            setIsCodeWaiting(true);
            dispatch(setUser({ nickname, email }));
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Something went wrong",
                message: error?.message,
            });
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const listener = eventListenerForKey({
        keyValue: "Enter",
        callback: handleSubmit,
        isCtrlPress: true,
    });

    useEffect(() => {
        if (isFormValid()) {
            document.addEventListener("keypress", listener, false);
        }
        return () => document.removeEventListener("keypress", listener, false);
    }, [isFormValid, listener]);

    const passwordCriteriaMetaLength = passwordCriteriaMeta.length;

    return (
        <div className="signup-form-wrapper">
            <form noValidate autoComplete="off" className="signup-form">
                {registrationFieldMeta.map((registrationField) => (
                    <TextField
                        key={registrationField.id}
                        id={registrationField.id}
                        label={registrationField.label}
                        value={registrationField.value}
                        type={registrationField.id}
                        required
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            onInputFormField(event, registrationField.setter)
                        }
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    className="dump-for-focus-fields"
                                    position="start"
                                />
                            ),
                        }}
                    />
                ))}
                <div className="additional-info">
                    <div className="errors-container">
                        {!!validationErrors &&
                            validationErrors?.map((validationError) => (
                                <AlertText
                                    key={validationError}
                                    message={validationError}
                                />
                            ))}
                    </div>
                    <div className="password-criteria-container errors-container">
                        <p>Include:</p>
                        {passwordCriteriaMeta.map((criteria, index) => (
                            <span key={criteria.message}>
                                <AlertText
                                    type={
                                        isEmpty(
                                            snetValidator.single(
                                                password,
                                                passwordInlineConstraints[
                                                    criteria.constraint
                                                ]
                                            )
                                        )
                                            ? alertTypes.SUCCESS
                                            : alertTypes.ERROR
                                    }
                                    message={criteria.message}
                                />
                                {index !== passwordCriteriaMetaLength - 1 &&
                                    ", "}
                            </span>
                        ))}
                    </div>
                    <div className="links-section">
                        {" "}
                        <p>
                            Already have an account?{" "}
                            <span
                                className="link-to-form"
                                onClick={setSignInForm}
                            >
                                Login
                            </span>
                        </p>
                    </div>
                </div>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={!!validationErrors || !isFilledAllFields}
                >
                    Create Account
                </Button>
            </form>
        </div>
    );
};

export default RegistrationForm;
