import React from "react";
import CodeSnippet from ".";

const codeSnippetData = {
    component: (
        <CodeSnippet>
            <code style={{ color: "lightGrey" }}> 
                {"// write your code"}
            </code>
        </CodeSnippet>
    ),
    import: `import CodeSnippet from '@integratedComponents/common/CodeSnippet';`,
    usage: `<CodeSnippet>\n\t<code style={{ color: 'lightGrey' }}>// write your code</code>\n</CodeSnippet>`,
    props: {
        children: `The content inside the CodeSnippet component, typically a <code> element containing your code.\n\nYou can style it as needed using inline styles or CSS classes.\n\nExample:\n<CodeSnippet>\n\t<code style={{ color: 'lightGrey' }}>console.log('Hello, world!');</code>\n</CodeSnippet>\n\n`,
    },
};

export default codeSnippetData;
