import Tabs from "@components/Tabs";
import FileTab from "@components/FileTab";
import FileExplorerContext from "@context/FileExplorerContext";
import FileExplorerToolbar from "@components/FileExplorerToolbar";
import TabletModeScreenSwitch from "@components/TabletModeScreenSwitch";
import { useContext, useMemo, type ReactElement } from "react";
import UserFile, { TUserFiles } from "@domains/File";
import { useSelector } from "react-redux";
import { TRootState } from "@store/index";
import "./style.css";

const FilesTabsController = (): ReactElement => {
    const isTabletMode: boolean = useSelector(
        (state: TRootState) => state.application.isTabletMode
    );

    const { filesList, currentFileId, setCurrentFileId } =
        useContext(FileExplorerContext);

    const getTabsList = (): TUserFiles => {
        return filesList.filter((file: UserFile) => file.getIsDisplayed());
    };

    const tabsList: TUserFiles = useMemo(getTabsList, [filesList]);

    const className: string = `file-explorer ${
        !isTabletMode ? "" : "tablet-mode"
    }`;

    return (
        <div className={className} id="file-explorer">
            <FileExplorerToolbar />
            <div className="files-tabs-list-wrapper">
                <Tabs
                    activeTabKey={currentFileId}
                    onTabChange={setCurrentFileId}
                >
                    {tabsList.map((file: UserFile) => (
                        <FileTab
                            file={file}
                            key={file.getId()}
                            tabId={file.getId()}
                            filesCount={filesList.length}
                        />
                    ))}
                </Tabs>
            </div>
            {isTabletMode && <TabletModeScreenSwitch />}
        </div>
    );
};

export default FilesTabsController;
