import SvgSpriteIcon from "@components/SvgSpriteIcon";
import "./style.css"
export interface ICloseButtonProps {
    onClick?: () => void;
}

const CloseButton = ({ onClick }: ICloseButtonProps) => {
    return (
        <div className="close-button-container" onClick={onClick}>
            <SvgSpriteIcon
                id={"icon-close-button"}
                className={"close-button "}
            />
        </div>
    );
};

export default CloseButton;
