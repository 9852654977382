import Tabs from "@components/Tabs";
import WorkspaceContext from "@context/WorkspaceContext";
import CodeCompilerContext from "@context/CodeCompilerContext";
import TabletModeScreenSwitch from "@components/TabletModeScreenSwitch";
import { DevToolsTabsKeys, previewTabsMap } from "./meta";
import { useContext, type ReactElement } from "react";
import { AliveScope } from "react-activation";
import { TRootState } from "@store/index";
import { useSelector } from "react-redux";
import "./style.css";

const PreviewTabsList = Object.values(previewTabsMap);

const DeveloperToolsPanel = (): ReactElement => {
    const { isPreviewCompiling } = useContext(CodeCompilerContext);
    const { developerToolsTab, setDeveloperToolsTab } =
        useContext(WorkspaceContext);

    const isTabletMode: boolean = useSelector(
        (state: TRootState) => state.application.isTabletMode
    );

    if (
        isPreviewCompiling &&
        developerToolsTab !== DevToolsTabsKeys.PREVIEW_AREA
    ) {
        setDeveloperToolsTab(DevToolsTabsKeys.PREVIEW_AREA);
    }

    return (
        <AliveScope>
            <div className="devtools-control-panel">
                <Tabs
                    activeTabKey={developerToolsTab}
                    onTabChange={setDeveloperToolsTab}
                >
                    {PreviewTabsList.map((tab) => (
                        <div key={tab.key}>{tab.label}</div>
                    ))}
                </Tabs>
                {isTabletMode && (
                    <TabletModeScreenSwitch isArrowInverted={true} />
                )}
            </div>
            <div className="devtools-panel-content">
                {previewTabsMap[developerToolsTab].component}
            </div>
        </AliveScope>
    );
};

export default DeveloperToolsPanel;
