import { ChangeEvent, Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "@store/index";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import snetValidator from "@utils/functions/snetValidator";
import AuthorizationService from "@services/AuthorizationService";
import { singupVerificationCodeContraints } from "./validationConstraints";
import "./styles.css";
import { setIsLoading } from "@store/slices/application";
import Timer from "@components/Timer";
import { InputAdornment } from "@mui/material";
import { eventListenerForKey } from "@utils/functions";
import useNotifications from "@hooks/useNotifications";
import { AlertTypes } from "@domains/Alert";

export interface IVerificationCodeForm {
    setSignInForm: () => void;
}

const VerificationCodeForm = ({ setSignInForm }: IVerificationCodeForm) => {
    const dispatch = useDispatch();
    const { createAlert } = useNotifications();
    const email = useSelector(
        (state: TRootState) => state.authorization.user.email
    );

    const [validationError, setValidationError] = useState<
        string | undefined
    >();
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [resendButtonDisabled, setResendButtonDisabled] =
        useState<boolean>(false);

    const isCodeValid = useCallback((): boolean => {
        const isNotValid = snetValidator(
            { otp: verificationCode },
            singupVerificationCodeContraints
        );
        if (isNotValid) {
            setValidationError(isNotValid[0]);
            return false;
        }
        setValidationError(undefined);
        return true;
    }, [verificationCode]);

    useEffect(() => {
        isCodeValid();
    }, [verificationCode, isCodeValid]);

    const handleResendOTP = async () => {
        try {
            dispatch(setIsLoading(true));
            await AuthorizationService.resendCode({ email });
            createAlert({
                type: AlertTypes.SUCCESS,
                title: "Check your mail!",
                message: "Code resent successfully",
            });
            setResendButtonDisabled(true);
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Something went wrong!",
                message: error?.message,
            });
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const handleConfirmSignup = async () => {
        try {
            dispatch(setIsLoading(true));
            await AuthorizationService.confirmSignUp({
                email,
                verificationCode,
            });
            setSignInForm();
        } catch (error: any) {
            createAlert({
                type: AlertTypes.ERROR,
                title: "Email confirmation failed",
                message: "Please try again",
            });
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const handleVerificationCode = (event: ChangeEvent<HTMLInputElement>) => {
        setVerificationCode(event.currentTarget.value);
    };

    const listener = eventListenerForKey({
        keyValue: "Enter",
        callback: handleConfirmSignup,
        isCtrlPress: true,
    });

    useEffect(() => {
        if (isCodeValid()) {
            document.addEventListener("keypress", listener, false);
        }
        return () => document.removeEventListener("keypress", listener, false);
    }, [isCodeValid, listener]);

    return (
        <Fragment>
            <p>
                A verification code has been sent to your registered email
                address. The code will be valid for 5 minutes.
            </p>

            <p>
                Please enter the verification code below to confirm your email
                address. Check your spam, or junk folders if you encounter any
                delays. The email should be from{" "}
                <strong>no-reply@singularitynet.io</strong>
            </p>
            <form className="signupForm">
                <TextField
                    id="outlined-confirm-otp"
                    label="Verification Code"
                    type="password"
                    variant="outlined"
                    required
                    value={verificationCode}
                    onChange={handleVerificationCode}
                    error={!!validationError && !!verificationCode}
                    helperText={!!validationError && validationError}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                className="dump-for-focus-fields"
                                position="start"
                            />
                        ),
                    }}
                />
                <div className="buttons-container">
                    <Button
                        variant="outlined"
                        onClick={handleResendOTP}
                        children={
                            !resendButtonDisabled ? (
                                "resend code"
                            ) : (
                                <Timer
                                    endTime={40}
                                    handleTimerCompletion={() =>
                                        setResendButtonDisabled(false)
                                    }
                                    interval={1}
                                />
                            )
                        }
                        disabled={resendButtonDisabled}
                    />
                    <Button
                        variant="contained"
                        onClick={handleConfirmSignup}
                        disabled={!!validationError}
                    >
                        Continue
                    </Button>
                    <Button
                        color="error"
                        variant="contained"
                        children="cancel"
                        onClick={setSignInForm}
                    />
                </div>
            </form>
        </Fragment>
    );
};

export default VerificationCodeForm;
