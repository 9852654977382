import JSZip, { type JSZipObject } from "jszip";
import UserFile, { TUserFileContent, TUserFiles } from "@domains/File";
import { FileIOErrors } from "@utils/constants";

export default class ZipService {
    public static downloadZip(zip: JSZip): void {
        zip.generateAsync({ type: "blob" }).then((blob: Blob) => {
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = "service.zip";
            downloadLink.click();
        });
    }

    public static uploadZip(): Promise<TUserFiles> {
        const fileInput: HTMLInputElement = document.createElement("input");

        fileInput.type = "file";
        fileInput.accept = ".zip";
        fileInput.multiple = false;

        return new Promise((resolve, reject) => {
            fileInput.onchange = async () => {
                const { files } = fileInput;
    
                if (!files?.length) {
                    reject("No files found on .zip uploader");
                }
    
                resolve(await this.getFilesFromZip(files![0]));
            };

            fileInput.oncancel = () => {
                reject("User rejected the file upload");
            }

            fileInput.click()
        });
    }

    public static createZip(files: UserFile[]): JSZip {
        const ZIP = new JSZip();

        files.forEach((file) => {
            const content: TUserFileContent = file.getContent();

            if (!content) {
                return;
            }

            ZIP.file(file.getFullName(), content);
        });

        return ZIP;
    }

    public static async importZipTemplate(
        templateName: string
    ): Promise<TUserFiles | null> {
        const response = await fetch(`/assets/templates/${templateName}`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await this.getFilesFromZip((await response.blob()) as File);
    }

    public static async getFilesFromZip(file: File): Promise<TUserFiles> {
        const ZIP = new JSZip();

        const unpackedFiles: JSZipObject[] = Object.values(
            (await ZIP.loadAsync(file)).files
        );

        if (!unpackedFiles?.length) {
            throw new Error(FileIOErrors.NO_UNZIPPED_FILES);
        }

        if (this.doesArchiveContainFolders(unpackedFiles)) {
            throw new Error(FileIOErrors.FOLDER_EXISTS_ERROR);
        }

        const files: UserFile[] = await Promise.all(
            unpackedFiles.map(async (unpackedFile) => {
                const file: UserFile = new UserFile();
                file.setContent(await unpackedFile.async("string"));
                file.setFullName(unpackedFile.name);
                return file;
            })
        );

        return files;
    }

    public static doesArchiveContainFolders(
        unpackedFiles: JSZipObject[]
    ): boolean {
        if (!unpackedFiles?.length) {
            return false;
        }

        if (unpackedFiles.some((unpackedFile) => unpackedFile.dir)) {
            return true;
        }

        return false;
    }
}
