import Api from "@api";
import FilesService from "@services/FilesService";
import useAlertsFactory from "@hooks/useNotifications";
import { Endpoints, TIMEOUT } from "@api/meta";
import { type TUserFiles } from "@domains/File";
import { AbortControllerFactory } from "@services/ApiService";
import { Dispatch, SetStateAction, useState } from "react";
import { AlertTypes } from "@domains/Alert";

export interface ICompilerContext {
    previewSourceUrl: string;
    isPreviewCompiling: boolean;
    compilationErrors: string[];
    setCompilationErrors: Dispatch<SetStateAction<string[]>>;
    compilePreview: (files: TUserFiles) => void;
    stopCompiling: () => void;
}

const usePreviewCompiler = (): ICompilerContext => {
    const { createAlert } = useAlertsFactory();
    const [previewSourceUrl, setPreviewSourceUrl] = useState<string>("");
    const [isCompilationCanceled, setIsCompilationCanceled] = useState<boolean>(false);
    const [compilationErrors, setCompilationErrors] = useState<string[]>([]);
    const [isPreviewCompiling, setIsPreviewCompiling] =
        useState<boolean>(false);
    const [currentAbortController, setCurrentAbortController] =
        useState<AbortController>();

    const reset = (): void => {
        setPreviewSourceUrl("");
        setCompilationErrors([]);
        setIsPreviewCompiling(false);
    }

    const compilePreview = async (files: TUserFiles): Promise<void> => {
        reset();

        try {
            setIsPreviewCompiling(true);

            const requestSuitableFiles: File[] =
                FilesService.convertTextToFiles(files);
            const formData = new FormData();

            requestSuitableFiles.forEach((file: File) => {
                formData.append("files", file);
            });

            const { abortController, cancelAbortOnTimeout } =
                AbortControllerFactory.createControllerWithTimeout(TIMEOUT);

            setCurrentAbortController(abortController);

            const responseData = await Api.post( // TODO API methods
                Endpoints.COMPILE,
                formData,
                abortController.signal
            );

            if (Array.isArray(responseData)) {
                setCompilationErrors(responseData);
            }

            cancelAbortOnTimeout();

            const bundleBlob = new Blob([responseData], {type: "text/javascript"}); // TODO to method
            const compiledModuleUrl = URL.createObjectURL(bundleBlob);

            if(!!previewSourceUrl) {
                URL.revokeObjectURL(previewSourceUrl);
            }

            setPreviewSourceUrl(compiledModuleUrl);
            setIsPreviewCompiling(false);
        } catch (error: any) {
            if (error?.name !== "AbortError") {
                setCompilationErrors(["Request failed", error?.message]);
                setIsPreviewCompiling(false);
                return;
            }
            if (!isCompilationCanceled) {
                createAlert(
                    {
                        type: AlertTypes.ERROR,
                        title: "Compilation error",
                        message: error?.message
                    }
                );
            }
            reset();
        }
    };
    
    const stopCompiling = (): void => {
        if (currentAbortController) {
            setIsCompilationCanceled(true);
            currentAbortController.abort();
        }
    };

    return {
        previewSourceUrl,
        compilationErrors,
        setCompilationErrors,
        isPreviewCompiling,
        compilePreview,
        stopCompiling,
    };
};

export default usePreviewCompiler;
