import React from "react";
import SNETImageUpload from ".";

const ImageUploaderWrapper = () => {
    const getImageData = (data) => {
        console.log("image data", data);
    };

    return (
        <SNETImageUpload
            style={{ align: "center" }}
            imageDataFunc={getImageData}
            imageName="Input"
            width="100%"
            disableUrlTab={true}
            disableComparisonTab={true}
            disableOutputTab={true}
            returnByteArray={true}
        />
    );
};

const ImageUploaderData = {
    component: <ImageUploaderWrapper />,
    import: "import SNETImageUpload from '@standardComponents/SNETImageUpload';",
    usage: `<SNETImageUpload\n\timageDataFunc={getImageData}\n\timageName={"Input"}\n\tdisableUrlTab={true}\n\tdisableComparisonTab={true}\n\tdisableOutputTab={true}\n\treturnByteArray={true}\n\twidth={"100%"}\n\tstyle={{ align: "center" }}\n/>`,
    props: {
        imageDataFunc: `Data handler for the uploaded image.\n\nAllowed values:\n\t- function(data) => void\n\n`,
        disableUrlTab: `Toggles upload by URL (Currently in incompatible mode, use 'true')\n\n`,
        disableComparisonTab: `Toggles input/output comparison (Currently in incompatible mode, use 'true')\n\n`,
        disableOutputTab: `Toggles output mode (Currently in incompatible mode, use 'true')\n\n`,
        returnByteArray: `Manages the output data format if 'true' then ByteArray used (UINT8), otherwise Base64\n\n`,
        width: `The width property in % of the parent's element width.\n\nExample:\n\twidth={"100%"}\n\n`,
    },
};

export default ImageUploaderData;
