import CompileButton from "@components/CompileButton";
// import TooltipWrapper from "@components/TooltipWrapper";
import CodeEditorContext from "@context/CodeEditorContext";
import FileExplorerContext from "@context/FileExplorerContext";
import CodeCompilerContext from "@context/CodeCompilerContext";
import { SwitchDirections, switchScreenSides } from "@components/WorkspacePanels";
import { ReactElement, useContext } from "react";
import { useSelector } from "react-redux";
import { TRootState } from "@store/index";
import "./style.css";

const FilesActionsToolbar = (): ReactElement => {
    const { filesList } = useContext(FileExplorerContext);
    const { isEditorLoading } = useContext(CodeEditorContext);
    const { isPreviewCompiling, compilePreview, stopCompiling } =
        useContext(CodeCompilerContext);

    const isTabletMode: boolean = useSelector(
        (state: TRootState) => state.application.isTabletMode
    );

    const compile = (): void => {
        compilePreview(filesList);
        isTabletMode && switchScreenSides(SwitchDirections.RIGHT);
    };

    const compileButtonAction = !isPreviewCompiling ? compile : stopCompiling;

    return (
        <div className="files-actions-toolbar">
            {/* <TooltipWrapper tooltipText="Compile and run preview"> */}
            <CompileButton
                onClick={compileButtonAction}
                isDisabled={isEditorLoading}
                isCompiling={isPreviewCompiling}
            />
            {/* </TooltipWrapper> */}
        </div>
    );
};

export default FilesActionsToolbar;
